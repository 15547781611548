import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import retrieveEnrichedActiveTemplateLearningObjectives from 'store/selectors/retrieveEnrichedActiveTemplateLearningObjectives';
import retrieveActiveCourseLearningObjectives, { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import QuestionBuilderLoTable from './QuestionBuilderLoTable';
import EditQuestionConfirmation from '../EditQuestionConfirmation/EditQuestionConfirmation';
import { UneditableSelectedLearningObjective } from 'instructor/controllers/Course/AssessmentBuilderController/AssessmentBuilderController.types';
import { QuestionUsageData } from '../QuestionBuilderController.types';
import { LibraryTypeEnum } from 'types/backend/shared.types';
import { AddRemoveEnum } from 'types/common.types';
import './QuestionBuilderLoChooser.scss';

function QuestionBuilderLoChooser({
  availableLos,
  selectedLoIds,
  templateReadOnlyLoIds,
  userReadOnlyLoIds,
  handleLoChange,
  questionUsageData,
  setQuestionUsageData,
  handleCopyQuestion,
  handleClose,
}: {
  availableLos: Array<EnrichedCourseLearningObjective>
  selectedLoIds: Array<number>
  templateReadOnlyLoIds: Array<number>
  userReadOnlyLoIds: Array<number>
  handleLoChange: (loAction: AddRemoveEnum, loId: number) => void
  questionUsageData: Array<QuestionUsageData> | null
  setQuestionUsageData: (questionUsageData: Array<QuestionUsageData> | null) => void
  handleCopyQuestion: () => Promise<void>
  handleClose: () => void
}) {
  const templateLearningObjectives = useSelector(retrieveEnrichedActiveTemplateLearningObjectives);
  const availableCourseLearningObjectives = useSelector(retrieveActiveCourseLearningObjectives);
  const selectedLos = availableLos.filter(({ id }) => selectedLoIds.includes(id));
  const allReadOnlyLoIds = templateReadOnlyLoIds.concat(userReadOnlyLoIds);
  const addableLos = availableLos.filter(({ id }) => !selectedLoIds.includes(id) && !allReadOnlyLoIds.includes(id));

  const getUneditableSelectedLos = (qloType: LibraryTypeEnum, loIds: Array<number>) => {
    return loIds.reduce((acc, cur) => {
      const foundClo = availableCourseLearningObjectives.find(({ id }) => id === cur);
      if (foundClo) {
        const { id, title, stringId, topicId, type, _derived: { loNumber } } = foundClo;
        acc.push({ id, stringId, title, topicId, type, _derived: { loNumber } });
      } else {
        const foundTemplateLo = templateLearningObjectives.find(({ id }) => id === cur);
        if (foundTemplateLo && qloType === LibraryTypeEnum.Template) {
          const { id, title, stringId, topicId, type } = foundTemplateLo;
          acc.push({ id, stringId, title, topicId, type, _derived: { loNumber: stringId } });
        }
      }
      return acc;
    }, [] as Array<UneditableSelectedLearningObjective>);
  };
  const uneditableSelectedTemplateLos = getUneditableSelectedLos(LibraryTypeEnum.Template, templateReadOnlyLoIds);
  const uneditableSelectedUserLos = getUneditableSelectedLos(LibraryTypeEnum.User, userReadOnlyLoIds);
  const hasSelectedLos = !!(selectedLoIds.length || (templateReadOnlyLoIds && templateReadOnlyLoIds.length) || (userReadOnlyLoIds && userReadOnlyLoIds.length));

  return (
    <>
      <div className="question-builder__tab lo-tab">
        <div className="lo-panels">
          <div className="top-panel">
            <h2>Select Learning Objectives</h2>
            {hasSelectedLos ? (
              <>
                <b className="question-builder__lo-header">Selected LOs</b>
                <div className="selected-los">
                  <QuestionBuilderLoTable
                    los={selectedLos}
                    mode={AddRemoveEnum.Remove}
                    onChangeLoSelectedState={(loId) => handleLoChange(AddRemoveEnum.Remove, loId)}
                    uneditableSelectedTemplateLos={uneditableSelectedTemplateLos}
                    uneditableSelectedUserLos={uneditableSelectedUserLos}
                  />
                </div>
              </>
            ) : (
              <div className="question-builder__needs-los">
                <div>Select Learning Objectives Below to Proceed</div>
              </div>
            )}
          </div>
          <div className="bottom-panel">
            <b className="question-builder__lo-header">Available LOs</b>
            <div className="available-los">
              <QuestionBuilderLoTable
                los={addableLos}
                mode={AddRemoveEnum.Add}
                onChangeLoSelectedState={(loId) => handleLoChange(AddRemoveEnum.Add, loId)}
                uneditableSelectedTemplateLos={[]}
                uneditableSelectedUserLos={[]}
              />
            </div>
          </div>
        </div>
      </div>
      {questionUsageData && !!questionUsageData.length && (
        <EditQuestionConfirmation
          handleContinue={() => setQuestionUsageData(null)}
          handleCopyQuestion={handleCopyQuestion}
          handleClose={handleClose}
          questionUsageData={questionUsageData}
        />
      )}
    </>
  );
}

QuestionBuilderLoChooser.propTypes = {
  availableLos: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedLoIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  templateReadOnlyLoIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  userReadOnlyLoIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleLoChange: PropTypes.func.isRequired,
  questionUsageData: PropTypes.arrayOf(PropTypes.object),
  setQuestionUsageData: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default QuestionBuilderLoChooser;
