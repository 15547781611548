// Annotated example of MUSCaTS import ordering for reference, see README for more info
// M - odules
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParam, useQueryParams, StringParam } from 'use-query-params';
import { DateTime } from 'luxon';
import isEqual from 'lodash-es/isEqual';
// U - tilities
import apiNext from 'api-next';
import getSortedUniqueLOsForClassSessions from 'utils/getSortedUniqueLOsForClassSessions';
import getUniqueLosSortedByLoNumber from 'utils/getUniqueLosSortedByLoNumber';
import useClassSessionQuery from 'hooks/useClassSessionQuery';
import { simpleConfirm } from 'utils';
import useEffectOnce from 'hooks/useEffectOnce';
import { useConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import sharedStrings from 'sharedStrings';
import enrichAssessmentForEditing, { EnrichedEditingAssessment } from './enrichAssessmentForEditing';
import {
  checkIfStudentsHaveStartedAssessment,
  getClassSessionIdsFromAssessments,
  getStudyPathEligibleAssessments,
  AvailableAssessmentForAssessmentBuilder,
  formatAssessmentForApi,
} from 'utils/assessmentFunctions';
import { loadStartedAssessmentQuestionIds } from 'utils/assessmentQuestionFunctions';
import getLoDataFromCombinedQuestion from 'utils/getLoDataFromCombinedQuestion';
import filterQuestions, { FilterState } from './filterQuestions';
import validateAssessmentUpdatesForWorker from 'utils/assessments/validateAssessmentUpdatesForWorker';
import validateAssessmentPublishedUpdatesForWorker from 'utils/assessments/validateAssessmentPublishedUpdatesForWorker';
import validateStudyPathAssessmentUpdatesForWorker from 'utils/assessments/validateStudyPathAssessmentUpdatesForWorker';
import { usePolling } from 'shared-components/Polling/PollingContext';
import { useToast } from 'shared-components/ToastNotification/ToastNotificationContext';

// S - tore
import activeSlice from 'store/slices/active';
import { useAppDispatch, useAppSelector } from 'store';
import addQuestionToAssessment from 'store/actions/addQuestionToAssessment';
import copyQuestion from 'store/actions/copyQuestion';
import createAssessment from 'store/actions/createAssessment';
import editAssessment from 'store/actions/editAssessment';
import updateStudyPath from 'store/actions/updateStudyPath';
import editAssessmentQuestionMap from 'store/actions/editAssessmentQuestionMap';
import removeAssessment from 'store/actions/removeAssessment';
import removeQuestionFromAssessment from 'store/actions/removeQuestionFromAssessment';
import retrieveActiveAssessmentQuestionMaps from 'store/selectors/retrieveActiveAssessmentQuestionMaps';
import addLoToClassSession from 'store/actions/addLoToClassSession';
import retrieveActiveClassSessions from 'store/selectors/retrieveActiveClassSessions';
import retrieveSortedActiveCombinedQuestions from 'store/selectors/retrieveSortedActiveCombinedQuestions';
import { ActiveCombinedQuestion } from 'store/selectors/retrieveActiveCombinedQuestions';
import retrieveEnrichedQuestionGroups, { EnrichedQuestionGroup } from 'store/selectors/retrieveEnrichedQuestionGroups';
import retrieveActiveCourseLearningObjectives, { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import addMultipleQuestionsToAssessment from 'store/actions/addMultipleQuestionsToAssessment';
import removeMultipleQuestionsFromAssessment from 'store/actions/removeMultipleQuestionsFromAssessment';
import reloadAssessmentQuestions from 'store/actions/reloadAssessmentQuestions';
import sortAssessmentQuestionMap from 'store/actions/sortAssessmentQuestionMap';
// C - omponents & Context
import Accommodations from './components/Accommodations/Accommodations';
import AssessmentBuilderActionBar, { ItemTypeEnum } from './components/AssessmentBuilderActionBar/AssessmentBuilderActionBar';
import AssessmentBuilderNav from './components/AssessmentBuilderNav/AssessmentBuilderNav';
import AssessmentDetails from './components/AssessmentDetails/AssessmentDetails';
import AssessmentQuestionSelector from './components/AssessmentQuestionSelector/AssessmentQuestionSelector';
import AssessmentsCovered from './components/AssessmentsCovered/AssessmentsCovered';
import AddUnalignedQuestionsConfirmationPrompt, {
  UnalignedActionsEnum,
  UnalignedQuestionsConfirmData,
} from './components/AddUnalignedQuestionsConfirmation/AddUnalignedQuestionsConfirmation';
import BetterModal from 'shared-components/BetterModal/BetterModal';
import BetterTimeline from 'shared-components/BetterTimeline/BetterTimeline';
import InstructorAssessmentPill from 'shared-components/BetterTimeline/InstructorAssessmentPill';
import LoadingSpinner from 'shared-components/Spinner/LoadingSpinner';
import QuestionBuilderController from '../QuestionBuilderController/QuestionBuilderController';
import QuestionPreview from 'shared-components/QuestionPreview/QuestionPreview';
import confirmationMsgs from './AssessmentBuilderConfirmationMessages';

// T - ypes
import {
  AssessmentWorkerChangeType,
  EnrollmentAssessmentRow,
  FormValues,
  QuestionActionEnum,
  QuestionActionPayload,
  TabEnum,
  TabNavigatePayload,
} from './AssessmentBuilderController.types';
import {
  ContextMethod,
  LibraryTypeEnum,
  QuestionUseEnum,
  YesNo,
} from 'types/backend/shared.types';
import { ApiError } from 'shared-components/ApiErrorDisplay/ApiErrorDisplay';
import { getSingleAssessmentQuestionMetadata, QuestionPreviewLaunchWithMetadata } from 'utils/getAssessmentQuestionsMetadata';
import { AssessmentApiBase, AssessTypeEnum, SummativeAssessmentApi } from 'types/backend/assessments.types';
import { DropdownOption } from 'instructor/components/Dropdown/Dropdown.types';
import { EnrollmentAssessmentApi } from 'types/backend/enrollmentAssessments.types';
import { QuestionApiOut } from 'types/backend/questions.types';
import { GradingTypeTag } from 'types/backend/l8y.types';
import { InitQuestionBuilder } from '../QuestionBuilderController/QuestionBuilderController.types';
import { InstructorCoursePath } from 'types/instructor.types';
import { ConfirmationTypeEnum, CreateAssessmentBody, CreateSummativeAssessmentBody } from 'types/common.types';
import { ClassSessionApi } from 'types/backend/classSessions.types';
import useLocalStorage from 'hooks/useLocalStorage';
import { WorkStatus } from 'types/backend/workerPipelines.types';

import './AssessmentBuilderController.scss';

const defaultFilterState: FilterState = {
  topics: [],
  los: [],
  blooms: [],
  author: [LibraryTypeEnum.Template, LibraryTypeEnum.User],
  assignment: true,
  questionUse: [QuestionUseEnum.Preclass, QuestionUseEnum.Postclass],
  questionTypes: [],
  gradingType: [GradingTypeTag.Assessment, GradingTypeTag.Survey],
};

const initRexFilterState = {
  questionUse: [QuestionUseEnum.Readiness],
};

export const initQuestionBuilderDefault: InitQuestionBuilder = {
  editingAssessmentId: null,
  blooms: null,
  l8yId: false,
  questionId: false,
  questionUse: QuestionUseEnum.Postclass,
  selectedLoIds: [],
  shortTitle: null,
  gradingType: null,
};

interface QuestionPreviewContextEnum {
  isProcessingPreview: boolean
  setIsProcessingPreview: (updated: boolean) => void
  checkIfAssessmentQuestionIsStarted: (aqId: number) => Promise<boolean>
  handleQuestionAction: (action: QuestionActionEnum, payload: QuestionActionPayload, isConfirmed?: boolean) => Promise<void>
}
export const QuestionPreviewContext = createContext({} as QuestionPreviewContextEnum);

interface AssessmentBuilderState {
  activeFilters: FilterState
  editingAssessment: EnrichedEditingAssessment | null
  groupedQuestions: Array<EnrichedQuestionGroup>
  hasBeenStarted: boolean
  isProcessing: boolean
  setActiveFilters: React.Dispatch<React.SetStateAction<FilterState>>
  showAllItems: () => void
}
export const AssessmentBuilderContext = createContext({} as AssessmentBuilderState);

export default function AssessmentBuilderController() {
  // init and query params
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { triggerConfirmationPrompt } = useConfirmationPrompt();
  const [returnTo] = useQueryParam('returnTo', StringParam);
  const [tabQuery, setTabQuery] = useQueryParam('tab', StringParam);
  const [qbStepQuery, setQbStepQuery] = useQueryParam('qbStep', StringParam);
  const [{ assessment: assessmentIdQuery, type: assessTypeQuery }, setQuery] = useQueryParams({
    assessment: StringParam,
    type: StringParam,
  });

  const ASSESSMENT_LOCAL_KEY = 'ASSESSMENT_JOB_ID';
  const STUDY_PATH_LOCAL_KEY = 'STUDY_PATH_JOB_ID';

  const toastAutoCloseDelay = Number(process.env.REACT_APP_TOAST_AUTOCLOSE_DELAY) || 3000;
  const initialPollingDelay = Number(process.env.REACT_APP_INITIAL_POLLING_DELAY) || 1000;
  const toastAutoCloseLongDelay = Number(process.env.REACT_APP_TOAST_AUTOCLOSE_LONG_DELAY) || 30000;

  const [assessmentJobId, setAssessmentJobId] = useLocalStorage(ASSESSMENT_LOCAL_KEY, '');
  const [assessmentWorkerChangeType, setAssessmentWorkerChangeType] = useState<AssessmentWorkerChangeType | null>(null);
  const [studyPathJobId, setStudyPathJobId] = useLocalStorage(STUDY_PATH_LOCAL_KEY, '');

  // selectors
  const assessments = useAppSelector((store) => store.active.assessments);
  const summativeAssessmentSupplements = useAppSelector((store) => store.active.summativeAssessmentSupplements);
  const students = useAppSelector((store) => store.active.students);
  const course = useAppSelector((store) => store.active.course);
  const user = useAppSelector((store) => store.user);
  const combinedQuestions = useAppSelector(retrieveSortedActiveCombinedQuestions);
  const learningObjectives = useAppSelector((store) => store.active.learningObjectives);
  const enrichedQuestionGroups = useAppSelector(retrieveEnrichedQuestionGroups);
  const assessmentQuestionMaps = useAppSelector(retrieveActiveAssessmentQuestionMaps);
  const classSessions = useAppSelector(retrieveActiveClassSessions);
  const courseLearningObjectives = useAppSelector(retrieveActiveCourseLearningObjectives);
  // this has to be useMemo because otherwise loadEnrollmentAssessmentInfo loops infinitely
  const studentsForDropdown: Array<DropdownOption<number>> = useMemo(() => students.map(s => ({
    value: s.enrollmentId as number,
    label: `${s.firstName} ${s.lastName}`,
  })), [students]);
  const enriched = enrichAssessmentForEditing(assessments, summativeAssessmentSupplements, assessmentIdQuery);

  const [selectedClassSessionId] = useClassSessionQuery(classSessions);
  // local state
  const [editingAssessment, setEditingAssessment] = useState<EnrichedEditingAssessment | null>(enriched);
  const [editingStudyPathId, setEditingStudyPathId] = useState(null as number | null);
  const [initialized, setInitialized] = useState(false);
  const [questionPreview, setQuestionPreview] = useState<QuestionPreviewLaunchWithMetadata | null>(null);
  const [initializedFilters, setInitializedFilters] = useState(false);
  const [showSummativeNav, setShowSummativeNav] = useState(assessTypeQuery === AssessTypeEnum.Summative);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isProcessingPreview, setIsProcessingPreview] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [startedAssessmentIds, setStartedAssessmentIds] = useState([] as Array<string>); //an array for ids of assessments that have been started by students. It's an array to handle the summative/prep/practice case
  const [enrollmentAssessmentInfo, setEnrollmentAssessmentInfo] = useState([] as Array<EnrollmentAssessmentRow>);
  const [prepEnrollmentAssessmentInfo, setPrepEnrollmentAssessmentInfo] = useState([] as Array<EnrollmentAssessmentRow>);
  const [practiceEnrollmentAssessmentInfo, setPracticeEnrollmentAssessmentInfo] = useState([] as Array<EnrollmentAssessmentRow>);
  const [startedAssessmentQuestionInfo, setStartedAssessmentQuestionInfo] = useState({} as { [key: string]: Array<number> });
  const [activeFilters, setActiveFilters] = useState(defaultFilterState);
  const [questionList, setQuestionList] = useState([] as Array<ActiveCombinedQuestion>);
  const [groupedQuestions, setGroupedQuestions] = useState([] as Array<EnrichedQuestionGroup>);
  // coveredAssessmentIds is meant to represent the server value of assessment, initialized from getStudyPathById, updated when assessments covered is submitted
  const [coveredAssessmentIds, setCoveredAssessmentIds] = useState([] as Array<string>);
  const [availableAssessments, setAvailableAssessments] = useState([] as Array<AvailableAssessmentForAssessmentBuilder>);
  const { notifySuccess, notifyError } = useToast();
  const { startPolling, stopPolling } = usePolling();
  const [isPrepHasChanged, setIsPrepHasChanged] = useState(false);
  const [isPracticeHasChanged, setIsPracticeHasChanged] = useState(false);

  const assessType = editingAssessment?.assessType || assessTypeQuery as AssessTypeEnum;

  let defaultQuestionUse = QuestionUseEnum.Postclass;
  if (assessType === AssessTypeEnum.Preclass) {
    defaultQuestionUse = QuestionUseEnum.Preclass;
  } else if (assessType === AssessTypeEnum.Readiness) {
    defaultQuestionUse = QuestionUseEnum.Readiness;
  }

  const [initQuestionBuilder, setInitQuestionBuilder] = useState<InitQuestionBuilder>({ ...initQuestionBuilderDefault, questionUse: defaultQuestionUse });
  // handle question editing data in state for the QuestionBuilder to reference when launched in an edit scenario
  const [questionBuilderMode, setQuestionBuilderMode] = useState(LibraryTypeEnum.User);
  const [unalignedQuestionsConfirmData, setUnalignedQuestionsConfirmData] = useState<UnalignedQuestionsConfirmData | null>(null);
  // tab navigation
  const questionBuilderTabs = [TabEnum.CreateQuestion, TabEnum.EditQuestion, TabEnum.EditQuestionLos];
  const initialTab = !!editingAssessment ? TabEnum.Editing : TabEnum.Creating;
  // set initial tab from query string, allows linking to an individual tab and reloading
  const tabFromQuery = !!tabQuery && Object.values(TabEnum).includes(tabQuery as TabEnum);
  const [tab, setTab] = useState(tabFromQuery ? tabQuery as TabEnum : initialTab);
  const [previousTab, setPreviousTab] = useState(initialTab);

  // set a boolean based on if the current active assessment (based on 'tab' to address summatives) has been started by any students
  const hasBeenStarted = (() => {
    if (!editingAssessment) {
      return false;
    }
    let questionSelectorActiveAssessmentId;
    switch (tab) {
      case TabEnum.SelectPracticeQuestions:
        questionSelectorActiveAssessmentId = editingAssessment.practiceTest.id;
        break;
      case TabEnum.SelectPrepQuestions:
        questionSelectorActiveAssessmentId = editingAssessment.prep.id;
        break;
      default:
        questionSelectorActiveAssessmentId = editingAssessment.id;
    }
    return startedAssessmentIds.includes(questionSelectorActiveAssessmentId);
  })();

  const triggerAssessmentWorker = async (
    assessment: EnrichedEditingAssessment,
    assessmentDelta: CreateAssessmentBody | CreateSummativeAssessmentBody,
    changeType: AssessmentWorkerChangeType,
    summativeMetadata?: { prepHasChanged: boolean; practiceHasChanged: boolean }
  ) => {
    const editedAssessment = { ...assessment, ...assessmentDelta };
    const updatedEditingAssessment = enrichAssessmentForEditing([editedAssessment], summativeAssessmentSupplements, assessmentIdQuery);
    setEditingAssessment(updatedEditingAssessment);
    const workerResult = await apiNext.postWorkerPipeline('assessments', ContextMethod.Update, editedAssessment);

    const { error, id } = workerResult;
    if (error) {
      return notifyError(`${assessment.name} update failed`, false);
    }

    if (summativeMetadata?.prepHasChanged) {
      setIsPrepHasChanged(true);
    }

    if (summativeMetadata?.practiceHasChanged) {
      setIsPracticeHasChanged(true);
    }

    setAssessmentJobId(id);
    setAssessmentWorkerChangeType(changeType);
  };

  const updateQuestionFiltersForStudyPath = useCallback((updatedCoveredAssessmentIds: Array<string>) => {
    const csIdsFromAssessments = getClassSessionIdsFromAssessments(assessments, updatedCoveredAssessmentIds);
    const losCoveredByAssessments = updatedCoveredAssessmentIds.reduce((acc, cur) => {
      const { courseLearningObjectives: clos } = availableAssessments.find(({ assessmentId }) => assessmentId === cur) || {};
      if (!!clos) {
        return [...acc, ...clos].flat();
      }
      return acc;
    }, [] as Array<EnrichedCourseLearningObjective>);
    const losCoveredByClassSessions = getSortedUniqueLOsForClassSessions(csIdsFromAssessments, classSessions);
    const uniquedPrepPtLos = getUniqueLosSortedByLoNumber([...losCoveredByAssessments, ...losCoveredByClassSessions]);
    setActiveFilters((currentFilters: FilterState) => ({
      ...currentFilters,
      questionUse: [QuestionUseEnum.Preclass, QuestionUseEnum.Postclass],
      los: uniquedPrepPtLos.map(({ id }) => id),
    }));
  }, [assessments, availableAssessments, classSessions]);

  const showAllItems = () => {
    setActiveFilters({
      ...defaultFilterState,
      questionUse: [
        ...defaultFilterState.questionUse,
        QuestionUseEnum.Readiness,
      ],
    });
  };

  const resetFilters = useCallback((assessTypeForFiltering?: AssessTypeEnum) => {
    // only include REX questions in filter by default when building a REX assessment
    if (assessTypeForFiltering === AssessTypeEnum.Readiness) {
      showAllItems();
    } else {
      setActiveFilters(defaultFilterState);
    }
  }, []);

  const getEarliestCoveredClassSessionId = (activeAssessment: EnrichedEditingAssessment) => {
    let allCoveredClassSessionIds = [] as Array<number>;
    if (activeAssessment.assessType === AssessTypeEnum.Summative) {
      allCoveredClassSessionIds = coveredAssessmentIds.reduce((acc, cur) => {
        const { classSessionIds = [] } = assessments.find(a => a.id === cur) || {};
        return [...acc, ...classSessionIds];
      }, [] as Array<number>);
    } else {
      allCoveredClassSessionIds = activeAssessment.classSessionIds;
    }
    const firstCoveredClassSession = classSessions.find(cs => allCoveredClassSessionIds.includes(cs.id)) as Pick<ClassSessionApi, 'id'>;
    return firstCoveredClassSession?.id || selectedClassSessionId || null;
  };


  /******************************************
   * EFFECTS
   *******************************************/
  useEffectOnce(() => {
    // if current tab is QuestionBuilder on reload (or bookmark, copypasta), return to SelectQuestions tab
    if (questionBuilderTabs.includes(tabQuery as TabEnum)) {
      console.warn(`Tried to load straight into QuestionBuilder, redirecting from ${tabQuery}`);
      if (assessType === AssessTypeEnum.Summative) {
        setTab(TabEnum.SelectPrepQuestions);
        setTabQuery(TabEnum.SelectPrepQuestions);
      } else {
        setTab(TabEnum.SelectQuestions);
        setTabQuery(TabEnum.SelectQuestions);
      }
    }
    // unset target qbStep on reload
    !!qbStepQuery && setQbStepQuery(undefined);
    // if reload on AssessmentReview, return to editing tab
    if (tabQuery === TabEnum.AssessmentReview) {
      setTab(TabEnum.Editing);
      setTabQuery(TabEnum.Editing);
    }
    if (!!editingAssessment?.assessType && !assessTypeQuery) {
      setQuery({ type: editingAssessment.assessType });
    }
  });

  // Set editingAssessment and reset filters when assessmentId is changed in url query
  useEffect(() => {
    // when assessment id query changes due to timeline nav
    if (!initialized || assessmentIdQuery !== editingAssessment?.id) {
      // updating redux state for editingAssessment
      const updatedEditingAssessment = enrichAssessmentForEditing(assessments, summativeAssessmentSupplements, assessmentIdQuery);
      setEditingAssessment(updatedEditingAssessment);
      if (!tabQuery && !!updatedEditingAssessment) {
        setTab(TabEnum.Editing);
      }
      setInitializedFilters(false);
      // only reset filters when not summative, which is covered by updateQuestionFiltersForStudyPath
      if (!!editingAssessment && updatedEditingAssessment?.assessType !== AssessTypeEnum.Summative) {
        // set up default values for filters
        // only include REX questions in filter by default when building a REX assessment
        resetFilters(updatedEditingAssessment?.assessType);
      }
    }
    setInitialized(true);
  }, [assessments, assessmentIdQuery, assessTypeQuery, dispatch, editingAssessment, initialized, resetFilters, summativeAssessmentSupplements, tabQuery]);

  //this useEffect orchestrates assessment worker pipeline process and its outcome
  useEffect(() => {
    if (!assessmentJobId) {
      return;
    }

    const pollingFunction = async () => {
      const response = await apiNext.getWorkerPipeline(assessmentJobId);
      const { workStatus } = response;

      if (workStatus !== WorkStatus.InProgress) {
        stopPolling(ASSESSMENT_LOCAL_KEY);
      }

      if (workStatus === WorkStatus.Completed) {
        const { outcome } = response;
        const studyPathMessage = outcome?.assessType === AssessTypeEnum.Summative ? 'Study Path' : '';
        if (assessmentWorkerChangeType === AssessmentWorkerChangeType.Dates) {
          notifySuccess(`${outcome?.name} ${studyPathMessage} ${sharedStrings.ASSESSMENT_DATES_UPDATE_SUCCESS}`, toastAutoCloseLongDelay);
        }

        if (outcome?.assessType === AssessTypeEnum.Summative) {
          const { prep: updatedPrep, practiceTest: updatedPractice } = outcome;
          dispatch(activeSlice.actions.editActiveAssessment({ id: updatedPrep.id, delta: updatedPrep }));
          dispatch(activeSlice.actions.editActiveAssessment({ id: updatedPractice.id, delta: updatedPractice }));
          if (isPrepHasChanged) {
            await dispatch(reloadAssessmentQuestions([outcome?.prep?.id]));
          }

          if (isPracticeHasChanged) {
            await dispatch(reloadAssessmentQuestions([outcome?.practiceTest?.id]));
          }
        }

        dispatch(activeSlice.actions.editActiveAssessment({
          id: outcome?.id,
          delta: {
            ...outcome as AssessmentApiBase | SummativeAssessmentApi,
          },
        }));
      }

      if (workStatus === WorkStatus.Failed) {
        const { error, payload } = response;
        const { message } = error as ApiError;
        if (assessmentWorkerChangeType === AssessmentWorkerChangeType.Published) {
          notifyError(`Update ${payload?.name} failed due to ${message}`, false);
        } else if (assessmentWorkerChangeType === AssessmentWorkerChangeType.Dates) {
          notifyError(`${payload?.name} date(s) extension failed due to ${message}`, false);
        }
      }
    };


    startPolling(pollingFunction, initialPollingDelay);
    return () => {
      if (!assessmentJobId) {
        stopPolling(ASSESSMENT_LOCAL_KEY);
      }
    };
  }, [assessmentJobId, dispatch, notifyError, notifySuccess, startPolling, stopPolling, initialPollingDelay, toastAutoCloseLongDelay, isPracticeHasChanged, isPrepHasChanged, assessmentWorkerChangeType]);

  // this useEffect orchestrates study-path worker pipeline process and its outcome
  useEffect(() => {
    if (!studyPathJobId) {
      return;
    }

    const pollingFunction = async () => {
      const response = await apiNext.getWorkerPipeline(studyPathJobId);
      const { workStatus } = response;

      if (workStatus !== WorkStatus.InProgress) {
        stopPolling(STUDY_PATH_LOCAL_KEY);
      }

      if (workStatus === WorkStatus.Failed) {
        const { error } = response;
        const { message } = error as ApiError;
        notifyError(`Update Study Path assessments covered failed due to ${message}`, false);
      }
    };

    startPolling(pollingFunction, initialPollingDelay);
    return () => {
      if (!studyPathJobId) {
        stopPolling(STUDY_PATH_LOCAL_KEY);
      }
    };
  }, [studyPathJobId, notifyError, startPolling, stopPolling, initialPollingDelay, toastAutoCloseLongDelay]);


  //this useEffect sets some state values if students have started the assessment when editingAssessment is changed (or set)
  useEffect(() => {
    async function updateAssessmentStudentUsage(assessmentToCheck: EnrichedEditingAssessment) {
      const startedIds = [];
      if (editingAssessment && assessmentToCheck.assessType === AssessTypeEnum.Summative) {
        const { prepAssessmentId, practiceAssessmentId } = editingAssessment._derived;
        if (prepAssessmentId) {
          const prepStarted = await checkIfStudentsHaveStartedAssessment(prepAssessmentId);
          if (prepStarted) {
            startedIds.push(prepAssessmentId);
          }
        }
        if (practiceAssessmentId) {
          const practiceStarted = await checkIfStudentsHaveStartedAssessment(practiceAssessmentId);
          if (practiceStarted) {
            startedIds.push(practiceAssessmentId);
          }
        }
      } else {
        const assessmentStarted = await checkIfStudentsHaveStartedAssessment(assessmentToCheck.id);
        if (assessmentStarted) {
          startedIds.push(assessmentToCheck.id);
        }
      }
      setStartedAssessmentIds(startedIds);
    }
    if (editingAssessment) {
      updateAssessmentStudentUsage(editingAssessment);
    }
  }, [editingAssessment]);

  /*
   *  Set our filtered results whenever the filters change
   */
  useEffect(() => {
    if (activeFilters) {
      const filteredQuestions = filterQuestions(assessmentQuestionMaps, combinedQuestions, activeFilters, editingAssessment?.id);
      const { filteredQuestionsUngrouped, filteredQuestionsGrouped } = filteredQuestions.reduce((acc, cur) => {
        if (!!cur.questionGroup) {
          acc.filteredQuestionsGrouped.push(cur);
        } else {
          acc.filteredQuestionsUngrouped.push(cur);
        }
        return acc;
      }, {
        filteredQuestionsUngrouped: [] as Array<ActiveCombinedQuestion>,
        filteredQuestionsGrouped: [] as Array<ActiveCombinedQuestion>,
      });
      const groupsToShow = enrichedQuestionGroups.filter(({ groupQuestionIds }) => {
        return groupQuestionIds.some((questionId) => !!filteredQuestionsGrouped.find(({ id }) => id === questionId));
      });
      setGroupedQuestions(groupsToShow);
      setQuestionList(filteredQuestionsUngrouped);
    }
  }, [activeFilters, assessmentQuestionMaps, combinedQuestions, editingAssessment, enrichedQuestionGroups]);

  /*
   * Initialize the filters with initial values
   */

  useEffect(() => {
    // update active filters when opening question select tabs
    const questionsTabs = [
      TabEnum.AssessmentReview,
      TabEnum.SelectQuestions,
      TabEnum.SelectPrepQuestions,
      TabEnum.SelectPracticeQuestions,
    ];
    // set LOs filters once on init then whenever editingAssessment.id changes
    if (editingAssessment && questionsTabs.includes(tab) && !initializedFilters) {
      const { classSessionIds } = editingAssessment;
      // los filtered uses different logic for prep/pt and pre/hw
      if (tab === TabEnum.SelectQuestions) {
        const classSessionClos = getSortedUniqueLOsForClassSessions(classSessionIds, classSessions);
        const classSessionCloIds = classSessionClos.map((lo) => lo.id);
        setActiveFilters((currentFilters: FilterState) => {
          if (editingAssessment.assessType === AssessTypeEnum.Readiness) {
            return {
              ...currentFilters,
              ...initRexFilterState,
              los: classSessionCloIds,
            };
          }
          return { ...currentFilters, los: classSessionCloIds };
        });
        setInitializedFilters(true);
      } else {
        updateQuestionFiltersForStudyPath(coveredAssessmentIds);
        setInitializedFilters(true);
      }
    }
  }, [classSessions, coveredAssessmentIds, editingAssessment, initializedFilters, tab, updateQuestionFiltersForStudyPath]);

  /*
   * Initialize covered assessments and LOs covered from editingAssessment
   */
  useEffect(() => {
    if (!!editingAssessment) {
      const { studyPathId } = editingAssessment;
      // SP data should fetch if study path id is not known or if it changes due to timeline navigation between SPs
      const shouldFetchStudyPathData = editingStudyPathId !== editingAssessment.studyPathId || !editingStudyPathId;
      if (!!studyPathId && shouldFetchStudyPathData && editingAssessment.assessType === AssessTypeEnum.Summative) {
        setEditingStudyPathId(studyPathId);
        const fetchStudyPathData = async () => await apiNext.getStudyPathById(studyPathId);
        // get assessmentIds from study path
        fetchStudyPathData().then(({ assessmentIds }) => {
          if (!!assessmentIds) {
            setCoveredAssessmentIds(assessmentIds);
            const eligibleAssessments = getStudyPathEligibleAssessments(editingAssessment, assessments, assessmentQuestionMaps, assessmentIds, course.timeZone);
            setAvailableAssessments(eligibleAssessments);
            // trigger filter re-initialize after get SP data to handle linking directly to tabs
            setInitializedFilters(false);
          }
        });
      }
    }
  }, [
    activeFilters,
    assessmentIdQuery,
    assessmentQuestionMaps,
    assessments,
    classSessions,
    editingAssessment,
    editingStudyPathId,
    course.timeZone,
  ]);

  /***
   * set a flag for the navigation to render summative assessment nav, if assessment is summative
   */
  useEffect(() => {
    const isEditingSummative = !!editingAssessment && (editingAssessment.assessType === AssessTypeEnum.Summative);
    const isSummative = isEditingSummative || assessType === AssessTypeEnum.Summative;
    setShowSummativeNav(isSummative);
    // when navigating from summative add-prep or add-practice tab to non-summative assessment, switch to select-questions tab
    // only do this after already initialized or else it overrides the requested tab url param
    if (initialized && !isSummative && [TabEnum.SelectPrepQuestions, TabEnum.SelectPracticeQuestions].includes(tab)) {
      setTab(TabEnum.SelectQuestions);
      setTabQuery(TabEnum.SelectQuestions);
    }
  }, [assessType, editingAssessment, initialized, setTabQuery, tab]);

  const currentAssessmentData: EnrichedEditingAssessment | AssessmentApiBase | null = getCurrentAssessmentForTab();

  /******************************************
   * COMPONENT METHODS
   */
  const handleNavigate = (targetTab: TabEnum, payload?: TabNavigatePayload) => {
    console.debug(`:: handleNavigate from ${tab} to ${targetTab}`);
    setPreviousTab(tab);
    if (formIsDirty || !editingAssessment) {
      console.warn(':: Attempting to navigate when form is dirty');
      if (!simpleConfirm(`${sharedStrings.ASSESSMENT_DETAILS_DIRTY_PREFIX} ${sharedStrings.DIRTY_NAVIGATE_CONFIRMATION}`)) {
        return;
      }
      setFormIsDirty(false); // reset formIsDirty if user moves on
    }
    if (targetTab === TabEnum.Exit) {
      return returnHome();
    }
    if ([TabEnum.CreateQuestion, TabEnum.EditQuestion, TabEnum.EditQuestionLos].includes(targetTab)) {
      // INIT QB FOR EDITING EXISTING QUESTION
      if (!!payload && [TabEnum.EditQuestion, TabEnum.EditQuestionLos].includes(targetTab)) {
        const selectedQuestion = payload as ActiveCombinedQuestion;
        launchQuestionBuilder(selectedQuestion);
      } else {
        // INIT QB FOR CREATING NEW QUESTION
        launchQuestionBuilder();
      }
    }
    setTab(targetTab);
    setTabQuery(targetTab);
  };

  const launchQuestionBuilder = (selectedQuestion?: ActiveCombinedQuestion | QuestionApiOut, copying?: boolean) => {
    // get correct assessment id depending on tab so we know what assessmentId we should be adding to
    const { id: editingAssessmentId = null } = getCurrentAssessmentForTab() || {};
    if (selectedQuestion) {
      const { allowAdd } = getSingleAssessmentQuestionMetadata(selectedQuestion, editingAssessment, hasBeenStarted, []);
      const {
        l8yId,
        id,
        type,
        blooms,
        questionUse,
        shortTitle,
        learningObjectiveIds,
        gradingType,
      } = selectedQuestion;

      const existingQuestionInit = {
        allowAdd,
        blooms,
        editingAssessmentId,
        l8yId,
        questionId: id,
        questionUse,
        shortTitle,
        gradingType,
      };

      setQuestionBuilderMode(type);
      if (copying) {
        // if we are copying a question, we don't have the full ActiveCombinedQuestion, so we just pass learningObjectiveIds to selectedLoIds
        setInitQuestionBuilder({
          ...existingQuestionInit,
          selectedLoIds: learningObjectiveIds,
        });
      } else {
        const { selectedLoIds = [] } = getLoDataFromCombinedQuestion(selectedQuestion as ActiveCombinedQuestion);
        setInitQuestionBuilder({
          ...existingQuestionInit,
          selectedLoIds,
        });
      }
    } else {
      // clear editing data if not editing
      setInitQuestionBuilder({
        ...initQuestionBuilderDefault,
        allowAdd: !hasBeenStarted || (hasBeenStarted && currentAssessmentData?.assessType === AssessTypeEnum.Prep),
        editingAssessmentId,
        questionUse: defaultQuestionUse,
      });
      setQuestionBuilderMode(LibraryTypeEnum.User);
    }

  };

  const moveOrDeleteErrorMessage = (actionType: QuestionActionEnum) => {
    if ([QuestionActionEnum.MoveQuestionToAssessment, QuestionActionEnum.RemoveQuestion].includes(actionType)) {
      const isMove = actionType === QuestionActionEnum.MoveQuestionToAssessment;
      return (
        <div>
          Students may have started answering this item before you tried to {isMove ? 'move' : 'remove'} it.
          Please reload your browser to update this assessment.
        </div>
      );
    }
    return '';
  };

  async function onSaveAssessmentDetails(assessmentDetails: FormValues) {
    const assessmentForApi = formatAssessmentForApi(assessType, assessmentDetails, course.id);
    if (editingAssessment) {
      if (!isEqual(editingAssessment.classSessionIds, assessmentDetails.classSessionIds)) {
        // reset the filters if covered LOs changes
        setInitializedFilters(false);
      }

      if (assessType === AssessTypeEnum.Summative && !!editingAssessment.studyPathId) {
        await dispatch(updateStudyPath(editingAssessment, assessmentDetails, startedAssessmentIds, triggerAssessmentWorker, notifySuccess)).then((editedSummative: SummativeAssessmentApi) => {
          const { prep, practiceTest } = assessmentForApi as CreateSummativeAssessmentBody;
          if (editedSummative) {
            const updatedEditingAssessment = enrichAssessmentForEditing([editedSummative], summativeAssessmentSupplements, assessmentIdQuery);
            const isLineItemFailed =
              (prep?.isGradeSyncEnabled && !editedSummative.prep?.isGradeSyncEnabled)
              || (practiceTest?.isGradeSyncEnabled && !editedSummative.practiceTest?.isGradeSyncEnabled);
            displayNotSyncedWarning(isLineItemFailed);
            if (!!updatedEditingAssessment) {
              setEditingAssessment((prev) => ({
                ...prev,
                ...updatedEditingAssessment,
              }));
            }
          }
        });
      } else {
        const isPublishedWorkerProcessingEligible = validateAssessmentPublishedUpdatesForWorker(editingAssessment, assessmentForApi);
        const isRegradeWorkerProcessingEligible = validateAssessmentUpdatesForWorker(editingAssessment, assessmentForApi, startedAssessmentIds);
        if (isPublishedWorkerProcessingEligible) {
          await triggerAssessmentWorker(editingAssessment, assessmentForApi, AssessmentWorkerChangeType.Published);
        } else if (isRegradeWorkerProcessingEligible) {
          notifySuccess(`${editingAssessment.name} ${sharedStrings.ASSESSMENT_DATES_UPDATE_IN_PROGRESS}`, toastAutoCloseDelay, 'info');
          await triggerAssessmentWorker(editingAssessment, assessmentForApi, AssessmentWorkerChangeType.Dates);
        } else {
          await dispatch(editAssessment(editingAssessment.id, assessmentForApi)).then((editedAssessment) => {
            const updatedEditingAssessment = enrichAssessmentForEditing([editedAssessment], summativeAssessmentSupplements, assessmentIdQuery);
            // NOTE: if assessment updated with gradeSyncEnabled flag but server responded with false value, it means server failed to create LineItem, show warning
            const isLineItemFailed = assessmentForApi.isGradeSyncEnabled && !editedAssessment.isGradeSyncEnabled;
            displayNotSyncedWarning(isLineItemFailed);
            setEditingAssessment(updatedEditingAssessment);
          });
        }
      }
      setFormIsDirty(false);
    } else {
      // @ts-expect-error this is necessary until I figure out TS types for thennable thunks
      await dispatch(createAssessment(assessmentForApi)).then(async (createdAssessment: SummativeAssessmentApi) => {
        const isLineItemFailed = assessmentForApi.isGradeSyncEnabled && !createdAssessment.isGradeSyncEnabled;
        displayNotSyncedWarning(isLineItemFailed);
        const { id: createdAssessmentId, studyPathId } = createdAssessment;
        if (assessType === AssessTypeEnum.Summative && !!studyPathId) {
          const { assessmentIds } = await apiNext.getStudyPathById(studyPathId);
          setCoveredAssessmentIds(assessmentIds);
          const eligibleAssessments = getStudyPathEligibleAssessments(createdAssessment, assessments, assessmentQuestionMaps, assessmentIds, course.timeZone);
          setAvailableAssessments(eligibleAssessments);
        }

        console.debug(`:: New ${assessType} Assessment created with id: ${createdAssessmentId}`);
        setQuery({ assessment: createdAssessmentId });
        setFormIsDirty(false);
      });
    }
  }

  async function handleSaveAssessmentsCovered(selectedAssessmentIds: Array<string>, updatedCoveredLos: Array<EnrichedCourseLearningObjective>) {
    const { studyPathId, published } = editingAssessment || {};
    if (!studyPathId) {
      console.error('studyPathId not valid, cannot patch study path', editingAssessment);
    } else {
      const assessmentIdsModified = JSON.stringify(selectedAssessmentIds) !== JSON.stringify(coveredAssessmentIds);
      // Only make patch call to server if selectedAssessmentIds is different from the initial coveredAssessmentIds value
      if (assessmentIdsModified) {
        updateQuestionFiltersForStudyPath(selectedAssessmentIds);
        const classSessionCloIds = updatedCoveredLos.map((lo) => lo.id);
        setActiveFilters(({ los: currentlyFilteringLos }: FilterState) => ({ ...activeFilters, los: [...currentlyFilteringLos, ...classSessionCloIds] }));
        setInitializedFilters(true);
        setCoveredAssessmentIds(selectedAssessmentIds);
        const isWorkerProcessingEligible = validateStudyPathAssessmentUpdatesForWorker(published as YesNo, coveredAssessmentIds, selectedAssessmentIds, assessments);
        if (isWorkerProcessingEligible) {
          const workerResult = await apiNext.postWorkerPipeline('study-paths', ContextMethod.Patch, { id: studyPathId, assessmentIds: selectedAssessmentIds });

          const { error, id } = workerResult;
          if (error) {
            return notifyError(`Study-Path ${studyPathId} update failed`, false);
          }

          setStudyPathJobId(id);
        } else {
          const assessmentsPatched = await apiNext.editStudyPath(studyPathId, selectedAssessmentIds);
          console.debug('assessments covered added to study path', assessmentsPatched);
        }
      }
    }
    setTab(TabEnum.SelectPrepQuestions);
    setFormIsDirty(false);
  }

  function displayNotSyncedWarning(isLineItemFailed: boolean | undefined) {
    if (isLineItemFailed) {
      triggerConfirmationPrompt({
        title: sharedStrings.NOT_SYNCED,
        message: confirmationMsgs.failedGradeSyncConfMessage,
        onConfirm: () => {},
        confirmationType: ConfirmationTypeEnum.Warn,
      });
    }
  }

  function returnHome() {
    if (!!returnTo) {
      history.push(returnTo);
    } else {
      const querySuffix = !!selectedClassSessionId ? `?class-session=${selectedClassSessionId}` : '';
      history.push(`/instructor/course/${course.id}/${InstructorCoursePath.DailyPlanner}${querySuffix}`);
    }
  }

  //backend handles deleting appropriate children and preventing delete is students have started taking assessment
  function onRemoveAssessment(assessmentId: string, isStarted: boolean) {
    if (isStarted || !simpleConfirm(sharedStrings.ASSESSMENT_DELETE_CONFIRMATION)) {
      return;
    }
    returnHome();
    dispatch(removeAssessment(assessmentId));
    setEditingAssessment(null);
  }

  //type used to be important pre-Strapi. Leaving it in for now, util we really deal with userQuestions
  function onChangeQuestionSort(assessmentQuestionId: number, previousId: number) {
    dispatch(sortAssessmentQuestionMap(assessmentQuestionId, previousId));
  }

  /**
   * this function returns the correct assessment object based on the current tab.
   */
  function getCurrentAssessmentForTab() {
    if (!!editingAssessment) {
      const { _derived: { practiceAssessmentId, prepAssessmentId } } = editingAssessment;
      // when copying a question, we need to check the previousTab because the current tab is EditQuestion but we still need to know which summative tab we're on
      if (tab === TabEnum.SelectPracticeQuestions || (tab === TabEnum.EditQuestion && previousTab === TabEnum.SelectPracticeQuestions)) {
        // adding classSessionIds here is a bandaid but the LO metrics tabs are going to be reworked soon
        const classSessionIds = getClassSessionIdsFromAssessments(assessments, coveredAssessmentIds);
        const practiceTestAssessment = assessments.find((a) => a.id === practiceAssessmentId) as AssessmentApiBase;
        console.assert(practiceTestAssessment, `practiceTestAssessment ${practiceAssessmentId} not found in assessments list`, assessments);
        return {
          ...practiceTestAssessment,
          classSessionIds,
        };
      }
      if (tab === TabEnum.SelectPrepQuestions || (tab === TabEnum.EditQuestion && previousTab === TabEnum.SelectPrepQuestions)) {
        const classSessionIds = getClassSessionIdsFromAssessments(assessments, coveredAssessmentIds);
        const prepAssessment = assessments.find((a) => a.id === prepAssessmentId) as AssessmentApiBase;
        console.assert(prepAssessment, `prepAssessment ${prepAssessmentId} not found in assessments list`, assessments);
        return {
          ...prepAssessment,
          classSessionIds,
        };
      }
      if (tab === TabEnum.SelectQuestions) {
        return editingAssessment;
      }
    }
    return null;
  }

  // get and create enrollment assessments
  const loadEnrollmentAssessmentInfo = useCallback(async () => {
    if (editingAssessment) {
      // TODO as EnrollmentAssessmentRow is hiding crimes here: https://github.com/codonlearning/web-application/pull/1420/files#r1483469864
      if (assessType === AssessTypeEnum.Summative) {
        const prepEnrollmentAssessments = await apiNext.getEnrollmentAssessmentsByAssessmentId(editingAssessment.prep.id);
        const prepStudentAccommodations = prepEnrollmentAssessments.map((ea) => {
          const studentInfo = studentsForDropdown.find(student => student.value === ea.enrollmentId) as DropdownOption<number>;
          return {
            ...ea,
            name: studentInfo.label,
          } as EnrollmentAssessmentRow;
        });
        const practiceEnrollmentAssessments = await apiNext.getEnrollmentAssessmentsByAssessmentId(editingAssessment.practiceTest.id);
        const practiceStudentAccommodations = practiceEnrollmentAssessments.map((ea) => {
          const studentInfo = studentsForDropdown.find(student => student.value === ea.enrollmentId) as DropdownOption<number>;
          return {
            ...ea,
            name: studentInfo.label,
          } as EnrollmentAssessmentRow;
        });
        setPrepEnrollmentAssessmentInfo(prepStudentAccommodations);
        setPracticeEnrollmentAssessmentInfo(practiceStudentAccommodations);
      } else {
        const enrollmentAssessments = await apiNext.getEnrollmentAssessmentsByAssessmentId(editingAssessment.id);
        const studentAccommodations = enrollmentAssessments.map((ea) => {
          const studentInfo = studentsForDropdown.find(student => student.value === ea.enrollmentId) as DropdownOption<number>;
          return {
            ...ea,
            name: studentInfo.label,
          } as EnrollmentAssessmentRow;
        });

        setEnrollmentAssessmentInfo(studentAccommodations);
      }
    }
  }, [editingAssessment, studentsForDropdown, assessType]);

  useEffect(() => {
    loadEnrollmentAssessmentInfo();
  }, [loadEnrollmentAssessmentInfo]);

  async function saveEnrollmentAssessment(eaAssessType: AssessTypeEnum, enrollmentIds: Array<number>, dueDate: Date) {
    if (!!editingAssessment) {
      let easToSearch = enrollmentAssessmentInfo;
      let eaAssessmentId = editingAssessment.id;
      if (eaAssessType === AssessTypeEnum.Prep) {
        easToSearch = prepEnrollmentAssessmentInfo;
        eaAssessmentId = editingAssessment.prep.id;
      } else if (eaAssessType === AssessTypeEnum.PracticeTest) {
        easToSearch = practiceEnrollmentAssessmentInfo;
        eaAssessmentId = editingAssessment.practiceTest.id;
      }
      await Promise.all(enrollmentIds.map(async (id) => {
        const existingAccommodation = easToSearch.find((accommodation) => accommodation.enrollmentId === id);
        if (existingAccommodation) {
          existingAccommodation.dueDate = DateTime.fromJSDate(dueDate).toISO();
          const {
            name, // name is not allowed in editEnrollmentAssessment so we get rid of it
            ...updatedEnrollmentAssessment
          } = existingAccommodation;
          await apiNext.editEnrollmentAssessment(updatedEnrollmentAssessment);
        } else {
          const enrollmentAssessment = {
            enrollmentId: id,
            assessmentId: eaAssessmentId,
            openDate: null,
            dueDate: DateTime.fromJSDate(dueDate).toISO(),
          } as EnrollmentAssessmentApi;
          await apiNext.createEnrollmentAssessment(enrollmentAssessment);
        }
      }));
      loadEnrollmentAssessmentInfo();
    }
  }

  async function deleteEnrollmentAssessment(enrollmentAssessmentId: number) {
    await apiNext.deleteEnrollmentAssessment(enrollmentAssessmentId);
    loadEnrollmentAssessmentInfo();
  }

  // this function checks to see if a single assessmentQuestion has been started by students
  async function checkIfAssessmentQuestionIsStarted(aqId: number) {
    const startedIds = await loadStartedAssessmentQuestionIds([aqId], course.id);
    if (startedIds.includes(aqId)) {
      return true;
    }
    return false;
  }

  // this function checks to see what AQs have been started by students and keeps that info in state
  // note that is intended for checking all AQs for a single assessment, not a subset.
  async function getStartedQuestionIds(assessmentId: string): Promise<Array<number>> {
    if (!students.length) {
      return [];
    }
    const startedIds = startedAssessmentQuestionInfo[assessmentId];
    if (startedIds) {
      return startedIds;
    } else {
      try {
        setIsProcessing(true);
        const aqmsForCurrentAssessment = assessmentQuestionMaps.filter(aqm => aqm.assessmentId === assessmentId);
        const aqmIds = aqmsForCurrentAssessment.map(aqm => aqm.id);
        const loadedAssessmentQuestionIds = await loadStartedAssessmentQuestionIds(aqmIds, course.id);

        const startedQuestionIds = aqmsForCurrentAssessment.reduce((acc, cur) => {
          if (loadedAssessmentQuestionIds.includes(cur.id)) {
            acc.push(cur.questionId);
          }
          return acc;
        }, [] as Array<number>);

        setStartedAssessmentQuestionInfo({
          ...startedAssessmentQuestionInfo,
          [assessmentId]: startedQuestionIds,
        });
        setIsProcessing(false);
        return startedQuestionIds;
      } catch (error) {
        console.error('getStartedQuestionIds error', error);
        setIsProcessing(false);
        return [];
      }
    }
  }

  async function handleCopyQuestion(questionId: number, shouldNavigate = false) {
    if (shouldNavigate) {
      setPreviousTab(tab);
      setIsProcessingPreview(true);
    }
    await dispatch(copyQuestion(questionId, user.id)).then((copiedQuestion: QuestionApiOut) => {
      if (shouldNavigate) {
        setIsProcessingPreview(false);
        // hide the Q Preview Modal
        setQuestionPreview(null);
      }
      // init question builder based on question from server
      launchQuestionBuilder(copiedQuestion, true);
      if (shouldNavigate) {
        setTab(TabEnum.EditQuestion);
        setTabQuery(TabEnum.EditQuestion);
      }
    });
  }

  // handler for actions from preview
  const handleQuestionActionFromPreview = (action: QuestionActionEnum, payload: QuestionActionPayload) => {
    const { questionId } = payload;
    handleQuestionAction(action, payload);
    // if a questionPreview has been set, update the preview questions to keep state in sync
    if (!!questionPreview) {
      const availableQuestion = combinedQuestions.find(({ id }) => id === questionId);
      switch (action) {
        case QuestionActionEnum.AddQuestion:
        case QuestionActionEnum.RemoveQuestion: {
          const { initialQuestionId, questions } = questionPreview;
          const updatedQuestions = questions.map(q => q.questionId === questionId ? {
            ...q,
            allowAdd: !q.allowAdd,
            allowRemove: !q.allowRemove,
          } : q);
          setQuestionPreview({ initialQuestionId, questions: updatedQuestions });
          break;
        }
        case QuestionActionEnum.EditQuestionLearningObjectives: {
          // hide the Q Preview Modal
          setQuestionPreview(null);
          if (!availableQuestion) {
            console.debug(`questionId ${questionId} not found in availableQuestions`);
            return;
          }
          handleNavigate(TabEnum.EditQuestionLos, availableQuestion);
          return;
        }
        case QuestionActionEnum.EditQuestion: {
          // hide the Q Preview Modal
          setQuestionPreview(null);
          if (!availableQuestion) {
            console.debug(`questionId ${questionId} not found in availableQuestions`);
            return;
          }
          handleNavigate(TabEnum.EditQuestion, availableQuestion);
          return;
        }
      }
    }
  };

  const handleAddQuestion = async (assessmentId: string, questionId: number) => {
    if (!selectedClassSessionId) {
      console.debug('no selectedClassSessionId, cannot add question');
      return;
    }
    setIsProcessing(true);
    // @ts-expect-error this is necessary until I figure out TS types for thennable thunks
    await dispatch(addQuestionToAssessment(questionId, assessmentId, editingAssessment.gradingPolicy)).then(() => {
      setIsProcessing(false);
    }).catch((err: ApiError) => {
      console.error(err);
      setIsProcessing(false);
      if (err.message) {
        triggerConfirmationPrompt({
          title: 'Error Adding Item',
          message: 'This item could not be added to this assessment.',
          onConfirm: () => {},
        });
      }
    });
  };

  async function handleQuestionAction(action: QuestionActionEnum, payload: QuestionActionPayload, isConfirmed = false) {
    console.debug(':: handleQuestionAction', action, payload);
    const { questionId, assessmentId, points, destinationAssessmentId } = payload;
    switch (action) {
      case QuestionActionEnum.CopyQuestion: {
        await handleCopyQuestion(questionId, false);
        return;
      }
      case QuestionActionEnum.AddQuestionGroup:
      case QuestionActionEnum.AddQuestionGroupAfterConfirmation:
      case QuestionActionEnum.AddQuestionGroupAfterConfirmationWithoutLos: {
        const { questionIds } = payload as { questionIds: Array<number> };
        if (!editingAssessment || !questionIds) {
          console.debug(':: handleQuestionAction, missing required elements', action, editingAssessment, questionIds, unalignedQuestionsConfirmData?.selectedQuestionIds);
          return;
        }
        const groupQuestions = combinedQuestions.filter(q => questionIds.includes(q.id));
        const questionsWithNoCourseNonCompetencyLos = groupQuestions.filter(q => !q.courseLearningObjectives.length && learningObjectives.some(lo => q.learningObjectiveIds.includes(lo.id) && lo.isCompetency === YesNo.No));
        const nonCompetencyQuestionLos = learningObjectives.filter(lo => questionsWithNoCourseNonCompetencyLos.some(q => q.learningObjectiveIds.includes(lo.id)) && lo.isCompetency === YesNo.No);
        if (action === QuestionActionEnum.AddQuestionGroup && !!questionsWithNoCourseNonCompetencyLos.length) {
          // throw confirmation prompt if any questions in the group have no course LOs and at least one non-competency LO
          const defaultClassSessionId = getEarliestCoveredClassSessionId(editingAssessment);
          if (!!defaultClassSessionId) {
            const questionGroup = enrichedQuestionGroups.find(eqg => questionIds.every(qid => eqg.groupQuestionIds.includes(qid)));
            setUnalignedQuestionsConfirmData({
              classSessionId: defaultClassSessionId,
              questionGroup,
              selectedLoIds: nonCompetencyQuestionLos.map(({ id }) => id),
              selectedQuestionIds: questionIds,
              assessmentId,
              unalignedActionType: UnalignedActionsEnum.AddQuestionGroup,
            });
          }
          return;
        } else {
          setIsProcessing(true);
          const { selectedQuestionIds = [], classSessionId, selectedLoIds = [] } = unalignedQuestionsConfirmData || {};
          const questionIdsToAdd = !!selectedQuestionIds.length ? selectedQuestionIds : questionIds;
          if (action === QuestionActionEnum.AddQuestionGroupAfterConfirmation && classSessionId) {
            for (const loId of selectedLoIds) {
              await dispatch(addLoToClassSession(loId, classSessionId, true));
            }
          }
          await dispatch(addMultipleQuestionsToAssessment(questionIdsToAdd, assessmentId, editingAssessment.gradingPolicy)).catch((err) => {
            console.error('logging error from AssessmentBuilderController', err);
            if (err.message) {
              setUnalignedQuestionsConfirmData(null);
              setIsProcessing(false);
              triggerConfirmationPrompt({
                title: 'Error Adding Group',
                message: `An unexpected error occurred when trying to add this group. ${err.message}`,
                onConfirm: () => {},
              });
            }
          });
          setUnalignedQuestionsConfirmData(null);
          setIsProcessing(false); // TODO: make sure this is handled in no LO case
        }
        return;
      }
      case QuestionActionEnum.RemoveQuestionGroup: {
        const { questionIds } = payload as { questionIds: Array<number> };
        setIsProcessing(true);
        if (!isConfirmed) {
          triggerConfirmationPrompt({
            title: 'Confirmation',
            message: sharedStrings.QUESTION_GROUP_DELETE_CONFIRMATION,
            confirmButtonText: 'Remove Question Group',
            onConfirm: () => handleQuestionAction(QuestionActionEnum.RemoveQuestionGroup, { assessmentId, questionId: -1, questionIds }, true),
            onCancel: () => setIsProcessing(false),
          });
          return;
        }
        await dispatch(removeMultipleQuestionsFromAssessment({ questionIds, assessmentId })).catch((err) => {
          console.error('logging error from AssessmentBuilderController', err);
          if (err.message) {
            setIsProcessing(false);
            triggerConfirmationPrompt({
              title: 'Error Removing Group',
              message: `An unexpected error occurred when trying to remove this group. ${err.message}`,
              onConfirm: () => {},
            });
          }
        });
        setIsProcessing(false);
        return;
      }
      case QuestionActionEnum.AddQuestion:
      case QuestionActionEnum.AddCslos: {
        if (!!editingAssessment) {
          const assessmentIdToAdd = assessmentId || editingAssessment.id;
          const questionToAdd = combinedQuestions.find(q => q.id === questionId);
          const nonCompetencyQuestionLos = learningObjectives.filter(lo => questionToAdd?.learningObjectiveIds?.includes(lo.id) && lo.isCompetency === YesNo.No);
          if (questionToAdd && !questionToAdd.courseLearningObjectives.length && !!nonCompetencyQuestionLos.length) {
            // if the question has no course LOs and the question is tagged with at least one LO that is NOT a competency LO, we need to see if an LO should be added
            const defaultClassSessionId = getEarliestCoveredClassSessionId(editingAssessment);
            if (!!defaultClassSessionId) {
              const questionGroup = enrichedQuestionGroups.find(eqg => eqg.groupQuestionIds.includes(questionToAdd.id));
              setUnalignedQuestionsConfirmData({
                assessmentId: assessmentIdToAdd,
                classSessionId: defaultClassSessionId,
                questionGroup,
                selectedLoIds: nonCompetencyQuestionLos.map(({ id }) => id),
                selectedQuestionIds: [questionToAdd.id],
                unalignedActionType: action === QuestionActionEnum.AddCslos ? UnalignedActionsEnum.AddLosOnly : UnalignedActionsEnum.AddSingleQuestion,
              });
            }
          } else {
            await handleAddQuestion(assessmentIdToAdd, questionId);
          }
        }
        return;
      }
      case QuestionActionEnum.AddQuestionWithLos:
      case QuestionActionEnum.AddCslosAfterConfirmation: {
        const { selectedLoIds = [], classSessionId } = unalignedQuestionsConfirmData || {};
        if (!classSessionId || !selectedLoIds.length) {
          console.error('missing unalignedQuestionConfirmData elements');
          return;
        }
        setIsProcessing(true);
        for (const loId of selectedLoIds) {
          await dispatch(addLoToClassSession(loId, classSessionId, true));
        }
        if (action === QuestionActionEnum.AddQuestionWithLos) {
          await handleAddQuestion(assessmentId, questionId);
        }
        setUnalignedQuestionsConfirmData(null);
        setIsProcessing(false);
        return;
      }
      case QuestionActionEnum.AddQuestionWithoutLos: {
        setIsProcessing(true);
        await handleAddQuestion(assessmentId, questionId);
        setUnalignedQuestionsConfirmData(null);
        setIsProcessing(false);
        return;
      }
      case QuestionActionEnum.RemoveQuestion: {
        if (!isConfirmed) {
          triggerConfirmationPrompt({
            title: 'Confirmation',
            message: sharedStrings.QUESTION_DELETE_CONFIRMATION,
            confirmButtonText: 'Remove Item',
            onConfirm: () => handleQuestionAction(QuestionActionEnum.RemoveQuestion, { questionId, assessmentId }, true),
            onCancel: () => {},
          });
          return;
        }
        await dispatch(removeQuestionFromAssessment({ questionId, assessmentId })).catch((err) => {
          console.error('logging error from AssessmentBuilderController', err);
          if (err.message) {
            triggerConfirmationPrompt({
              title: 'Error Removing Item',
              message: moveOrDeleteErrorMessage(QuestionActionEnum.RemoveQuestion),
              onConfirm: () => {},
            });
          }
        });
        return;
      }
      case QuestionActionEnum.AdjustPoints: {
        if (points) {
          const relatedAssessmentQuestionMap = assessmentQuestionMaps.find((aqm) => aqm.questionId === questionId && aqm.assessmentId === assessmentId);
          if (relatedAssessmentQuestionMap) {
            dispatch(editAssessmentQuestionMap(relatedAssessmentQuestionMap.id, { points: parseFloat(points) }, true));
          }
        }
        return;
      }
      case QuestionActionEnum.MoveQuestionToAssessment: {
        // Moving a question is a multistep process
        // - remove the question
        // - add the question
        // - re-add the original if the add fails
        let wasRemoved = true;
        let wasAdded = true;
        let wasReAdded = true;

        if (!destinationAssessmentId) {
          console.debug('missing destinationAssessmentId');
          return;
        }
        const { gradingPolicy: destinationGradingPolicy } = assessments.find(a => a.id === destinationAssessmentId) as AssessmentApiBase;
        await dispatch(removeQuestionFromAssessment({ questionId, assessmentId })).catch((err) => {
          console.error('logging error from AssessmentBuilderController, moving a question, part I, removing the AQM. Logging payload then error', payload, err);
          wasRemoved = false;
          if (err.message) {
            triggerConfirmationPrompt({
              title: sharedStrings.MOVE_QUESTION_ERROR_TITLE,
              message: moveOrDeleteErrorMessage(QuestionActionEnum.MoveQuestionToAssessment),
              onConfirm: () => {},
            });
          }
        });
        if (!wasRemoved) {
          return;
        }
        // @ts-expect-error this is necessary until I figure out TS types for thennable thunks
        await dispatch(addQuestionToAssessment(questionId, destinationAssessmentId, destinationGradingPolicy, points)).catch((err) => {
          console.error('logging error from AssessmentBuilderController, moving a question, part II, adding the AQM after successful remove. Logging payload then error', payload, err);
          wasAdded = false;
        });
        if (!wasAdded) {
          // @ts-expect-error this is necessary until I figure out TS types for thennable thunks
          await dispatch(addQuestionToAssessment(questionId, assessmentId, editingAssessment?.gradingPolicy, points)).catch((error) => {
            console.error('logging error from AssessmentBuilderController, moving a question, part III, re-adding the AQM after successful remove and failed add. Logging payload then error', payload, error);
            wasReAdded = false;
          });
        }
        if (!wasAdded || !wasReAdded) {
          const displayMessage = `This item could not be moved.${wasReAdded ? '' : ' It is no longer part of the original assessment and will need to be re-added.'}`;
          triggerConfirmationPrompt({
            title: sharedStrings.MOVE_QUESTION_ERROR_TITLE,
            message: displayMessage,
            onConfirm: () => {},
          });
        }
        return;
      }
    }
  }

  /******************************************
   * Render
   */

  const { los: filterLos } = activeFilters;
  const selectedCourseLos = filterLos.map((lo: number) => courseLearningObjectives.find(clo => clo.id === lo) as EnrichedCourseLearningObjective);
  const selectedLos = getUniqueLosSortedByLoNumber(selectedCourseLos);

  const renderQuestionPreview = (questionPreviewData: QuestionPreviewLaunchWithMetadata | null) => {
    if (!questionPreviewData || !questionPreviewData.questions.length || !questionList || !currentAssessmentData) {
      return <></>;
    }
    const { questions } = questionPreviewData;

    return (
      <QuestionPreviewContext.Provider value={{ handleQuestionAction, isProcessingPreview, setIsProcessingPreview, checkIfAssessmentQuestionIsStarted }}>
        <QuestionPreview
          userId={user.id}
          triggerQuestionAction={(action, payload) => handleQuestionActionFromPreview(action, { ...payload, assessmentId: currentAssessmentData.id })}
          questions={questions}
          initialQuestionId={questionPreviewData.initialQuestionId}
          launchAssessmentId={questionPreviewData.activeAssessmentId}
          isAssessmentBuilder
          onCopyQuestion={handleCopyQuestion}
        />
      </QuestionPreviewContext.Provider>
    );
  };

  const accommodationsRightSideButtons = [
    {
      className: 'assessment-builder__back',
      itemType: ItemTypeEnum.SecondaryBtn,
      itemId: 'assessmentsQuestionSelectorBack',
      itemText: 'BACK',
      onClick: () => handleNavigate(TabEnum.Editing),
    },
    {
      className: 'assessment-builder__finish',
      itemType: ItemTypeEnum.PrimaryBtn,
      itemId: 'returnToOverview',
      itemText: 'RETURN TO COURSE OVERVIEW',
      onClick: () => handleNavigate(TabEnum.Exit),
    },
  ];

  const Tabs = (currentTab: TabEnum) => {
    // if in QuestionBuilder, don't render AB tab because it will be hidden by the modal anyway
    if (questionBuilderTabs.includes(currentTab)) {
      return null;
    }
    switch (currentTab) {
      case TabEnum.Creating:
      case TabEnum.Editing: {
        if (!initialized) {
          return null;
        }
        return (
          <AssessmentDetails
            key={editingAssessment?.id || 'new'}
            setTab={setTab}
            onSaveAssessmentDetails={onSaveAssessmentDetails}
            onRemoveAssessment={onRemoveAssessment}
            setFormIsDirty={setFormIsDirty}
            startedAssessmentIds={startedAssessmentIds}
          />
        );
      }
      case TabEnum.AssessmentReview: {
        if (!editingAssessment || !availableAssessments) {
          return <LoadingSpinner />;
        }
        return (
          <AssessmentsCovered
            availableAssessments={availableAssessments}
            coveredAssessmentIds={coveredAssessmentIds}
            navigate={handleNavigate}
            onChange={updateQuestionFiltersForStudyPath}
            onSave={handleSaveAssessmentsCovered}
            selectedLos={selectedLos}
            setFormIsDirty={setFormIsDirty}
          />
        );
      }
      case TabEnum.EditAccommodations: {
        if (!editingAssessment) {
          return null;
        }
        return (
          <>
            <Accommodations
              assessment={editingAssessment}
              students={studentsForDropdown}
              enrollmentAssessments={enrollmentAssessmentInfo}
              saveEnrollmentAssessment={saveEnrollmentAssessment}
              deleteEnrollmentAssessment={deleteEnrollmentAssessment}
            />
            <AssessmentBuilderActionBar
              rightSideButtons={accommodationsRightSideButtons}
            />
          </>
        );
      }
      case TabEnum.EditSummativeAccommodations: {
        if (!editingAssessment) {
          return null;
        }
        return (
          <>
            <Accommodations
              assessment={editingAssessment.prep}
              students={studentsForDropdown}
              enrollmentAssessments={prepEnrollmentAssessmentInfo}
              saveEnrollmentAssessment={saveEnrollmentAssessment}
              deleteEnrollmentAssessment={deleteEnrollmentAssessment}
              titleText='Prep Questions'
              className='top-box'
            />
            <Accommodations
              assessment={editingAssessment.practiceTest}
              students={studentsForDropdown}
              enrollmentAssessments={practiceEnrollmentAssessmentInfo}
              saveEnrollmentAssessment={saveEnrollmentAssessment}
              deleteEnrollmentAssessment={deleteEnrollmentAssessment}
              titleText='Practice Test'
            />
            <AssessmentBuilderActionBar
              rightSideButtons={accommodationsRightSideButtons}
            />
          </>
        );
      }
      default: {
        if (!currentAssessmentData) {
          console.debug('currentAssessmentData not loaded');
          return <LoadingSpinner />;
        }

        return (
          <main className="course-page__assessments-wrapper">
            <AssessmentQuestionSelector
              currentAssessment={currentAssessmentData}
              key={currentAssessmentData.id}
              currentTab={currentTab}
              navigate={handleNavigate}
              onChangeQuestionSort={onChangeQuestionSort}
              onFinalize={returnHome}
              questionAction={handleQuestionAction}
              questionList={questionList}
              selectedLos={selectedLos}
              setQuestionPreview={setQuestionPreview}
              getStartedQuestionIds={getStartedQuestionIds}
            />
          </main>
        );
      }
    }
  };

  if (!selectedClassSessionId) {
    return <LoadingSpinner />;
  }

  return (
    <AssessmentBuilderContext.Provider
      value={{
        activeFilters,
        editingAssessment,
        groupedQuestions,
        hasBeenStarted,
        isProcessing,
        setActiveFilters,
        showAllItems,
      }}
    >
      <div className="assessments-controller-root">
        {!!unalignedQuestionsConfirmData && (
          <AddUnalignedQuestionsConfirmationPrompt
            handleCancel={() => setUnalignedQuestionsConfirmData(null)}
            handleQuestionAction={handleQuestionAction}
            setUnalignedQuestionsConfirmData={setUnalignedQuestionsConfirmData}
            unalignedQuestionsConfirmData={unalignedQuestionsConfirmData}
          />
        )}
        <div className="assessments-controller">
          <BetterTimeline
            currentClassSessionId={selectedClassSessionId}
            renderAssessmentPill={(assessmentPillData) => (
              <InstructorAssessmentPill {...assessmentPillData} />
            )}
            allowReturnToDailyPlanner
          />
          <AssessmentBuilderNav
            assessmentName={editingAssessment?.name}
            assessType={assessType}
            currentTab={tab}
            handleExit={returnHome}
            isSummative={showSummativeNav}
            navigate={handleNavigate}
          />
          <main className="assessments-controller__tab-wrap">
            {Tabs(tab)}
          </main>
          {questionBuilderTabs.includes(tab) && (
            <div className="modal__question-builder">
              <QuestionBuilderController
                handleClose={() => handleNavigate(previousTab)}
                handleQuestionAction={handleQuestionAction}
                initQuestionBuilder={initQuestionBuilder}
                mode={questionBuilderMode}
              />
            </div>
          )}
          <BetterModal className="assessment-builder-controller__preview-modal" isShowing={!!questionPreview} hide={() => setQuestionPreview(null)} >
            {renderQuestionPreview(questionPreview)}
          </BetterModal>
        </div>
      </div>
    </AssessmentBuilderContext.Provider>
  );
}
