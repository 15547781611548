import { BetterClassSession } from 'store/selectors/retrieveBetterClassSessions';

export enum ClassSessionDependencies {
  Topics = 'topics',
  LearningObjectives = 'learning objectives',
  InClassMaterials = 'in-class materials',
  PreclassResources = 'pre-class resources'
}

export const canSessionBeSpecial = (classSession: BetterClassSession) => {
  const { topics, courseLearningObjectives, iclrs, ooclrs } = classSession;

  const dependencies = [];
  if (!!topics.length) {
    dependencies.push(ClassSessionDependencies.Topics);
  }
  if (!!courseLearningObjectives.length) {
    dependencies.push(ClassSessionDependencies.LearningObjectives);
  }
  if (!!iclrs.length) {
    dependencies.push(ClassSessionDependencies.InClassMaterials);
  }
  if (!!ooclrs.length) {
    dependencies.push(ClassSessionDependencies.PreclassResources);
  }

  return {
    canSessionBeMadeSpecial: !dependencies.length,
    sessionDependencies: dependencies,
  };
};
