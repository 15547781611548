import React, {
  ReactNode,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { FaArrowLeft, FaArrowRight, FaPlus } from 'react-icons/fa';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';

import useEffectOnce from 'hooks/useEffectOnce';
import apiNext from 'api-next';
import { isInThePast } from 'utils/dateFormattingFunctions';
import { AssessmentBuilderContext } from '../AssessmentBuilderController/AssessmentBuilderController';
import { useConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import { useAppDispatch, useAppSelector } from 'store';
import createUserQuestion from 'store/actions/createUserQuestion';
import updateUserQuestion from 'store/actions/updateUserQuestion';
import updateUserQuestionLosForTemplateQuestion from 'store/actions/updateUserQuestionLosForTemplateQuestion';
import updateUserQuestionLosForUserQuestion from 'store/actions/updateUserQuestionLosForUserQuestion';
import retrieveActiveCourseLearningObjectives from 'store/selectors/retrieveActiveCourseLearningObjectives';
import BloomsDropdown from 'instructor/components/Dropdown/BloomsDropdown';
import LoadingSpinner from 'shared-components/Spinner/LoadingSpinner';
import QuestionBuilderFooter from './QuestionBuilderFooter';
import QuestionBuilderLoChooser from './QuestionBuilderLoChooser/QuestionBuilderLoChooser';
import QuestionUseDropdown from 'instructor/components/Dropdown/QuestionUseDropdown';
import LearnosityAuthorContainer from './LearnosityAuthorContainer';
import { AddRemoveEnum, ConfirmationTypeEnum, LeftRightEnum } from 'types/common.types';
import { LibraryTypeEnum, QuestionUseEnum } from 'types/backend/shared.types';
import { QuestionLearningObjectiveApi } from 'types/backend/questionLearningObjectives.types';
import { AuthorAppGetItem } from 'types/backend/l8yItems.types';
import { OnL8yAuthorEvent, L8yAuthorRoute, L8yAuthorStatus } from './LearnosityAuthorContainer.types';
import { QuestionActionEnum, QuestionActionPayload } from '../AssessmentBuilderController/AssessmentBuilderController.types';
import { AssessmentApiBase } from 'types/backend/assessments.types';
import { CourseApi } from 'types/backend/courses.types';
import { GradingTypeTag } from 'types/backend/l8y.types';
import {
  ButtonAlias,
  ButtonTypeEnum,
  InitQuestionBuilder,
  QuestionBuilderFooterObject,
  QuestionBuilderStep,
  QuestionUsageData,
} from './QuestionBuilderController.types';
import './QuestionBuilderController.scss';

function QuestionBuilderController({
  initQuestionBuilder,
  handleClose,
  handleQuestionAction,
  mode,
}: {
  initQuestionBuilder: InitQuestionBuilder
  handleClose: () => void
  mode: LibraryTypeEnum
  handleQuestionAction?: (action: QuestionActionEnum, payload: QuestionActionPayload) => Promise<void>
}) {
  const [isCustomQuestion, setIsCustomQuestion] = useState(mode === LibraryTypeEnum.User);
  const [stepQuery, setStep] = useQueryParam('qbStep', withDefault(StringParam, QuestionBuilderStep.ChooseLO));
  const step = stepQuery as QuestionBuilderStep;
  const { triggerConfirmationPrompt } = useConfirmationPrompt();
  const { hasBeenStarted } = useContext(AssessmentBuilderContext);
  const dispatch = useAppDispatch();
  const user = useAppSelector((store) => store.user);
  const course = useAppSelector((store) => store.active.course);
  const instructors = useAppSelector((store) => store.active.instructors);
  const enrolledCourses = useAppSelector((store) => store.passive.instructorCourses);
  const templateQuestions = useAppSelector((store) => store.active.templateQuestions);
  const availableCourseLearningObjectives = useAppSelector(retrieveActiveCourseLearningObjectives);

  const instructorUserIds = instructors.map(({ id }) => id);
  const availableCourseLearningObjectivesForUser = availableCourseLearningObjectives.filter(({ type, userId }) => type === LibraryTypeEnum.Template || userId === user.id);

  const { allowAdd, editingAssessmentId } = initQuestionBuilder;
  // questionId is either known from the beginning, or gets set on save
  const [questionId, setQuestionId] = useState<number | false>(initQuestionBuilder.questionId);
  const [isLoadingQuestionUseData, setIsLoadingQuestionUseData] = useState(isCustomQuestion && !!questionId);
  const [isCopyingQuestion, setIsCopyingQuestion] = useState(false);
  const [questionUsageData, setQuestionUsageData] = useState<Array<QuestionUsageData> | null>(null);
  const [questionLos, setQuestionLos] = useState([] as Array<QuestionLearningObjectiveApi>);
  const [templateReadOnlyLoIds, setTemplateReadOnlyLoIds] = useState([] as Array<number>);
  const [userReadOnlyLoIds, setUserReadOnlyLoIds] = useState([] as Array<number>);
  const [isSaving, setIsSaving] = useState(false);
  const [l8yAuthorStatus, setL8yAuthorStatus] = useState(L8yAuthorStatus.Init);
  // there is no l8y event for edits within widgets so if any widget edit page is loaded, treat as changed
  const [shouldWarnOnCancel, setShouldWarnOnCancel] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // QuestionBuilderStep.ChooseLO State
  const [{ questionLoIdsToRemove, selectedLoIds, selectedLoIdsChanged }, setSelectedLoIds] = useState({
    questionLoIdsToRemove: [] as Array<number>,
    selectedLoIds: [] as Array<number>,
    selectedLoIdsChanged: false,
  });
  const [editingGradingType, setEditingGradingType] = useState(initQuestionBuilder.gradingType);

  // QuestionBuilderStep.Authoring state
  const [editingL8yId, setEditingL8yId] = useState(initQuestionBuilder.l8yId);
  // keep itemJson in state because we need it to update shortTitle, questionUse, blooms on Reviewing panel
  const [itemJson, setItemJson] = useState(null as AuthorAppGetItem | null);

  // QuestionBuilderStep.Reviewing State
  interface ReviewingState {
    editingBlooms: number | null
    editingQuestionUse: QuestionUseEnum
    editingShortTitle: string | null
    reviewingStateChanged: boolean
  }
  const [{ editingBlooms, editingQuestionUse, editingShortTitle, reviewingStateChanged }, setReviewingState] = useReducer((prev: ReviewingState, next: Partial<ReviewingState>) => {
    const updatedState = { ...prev, ...next };
    const stateChanged = [
      updatedState.editingBlooms !== initQuestionBuilder.blooms,
      updatedState.editingQuestionUse !== initQuestionBuilder.questionUse,
      updatedState.editingShortTitle !== initQuestionBuilder.shortTitle,
    ].some(Boolean);
    return {
      ...updatedState,
      reviewingStateChanged: stateChanged,
    };
  }, {
    editingBlooms: initQuestionBuilder.blooms,
    editingQuestionUse: initQuestionBuilder.questionUse,
    editingShortTitle: initQuestionBuilder.shortTitle,
    reviewingStateChanged: false,
  });

  const editingExistingQuestion = !!editingL8yId;

  useEffectOnce(() => {
    (async () => {
      const questionLearningObjectives = !!questionId ? await apiNext.getQuestionLos(questionId, instructorUserIds) : [];
      const templateQloLoIds = questionLearningObjectives.filter(({ type }) => type === LibraryTypeEnum.Template).map(({ learningObjectiveId }) => learningObjectiveId);
      const userQlos = questionLearningObjectives.filter(({ type }) => type === LibraryTypeEnum.User);
      const uniqueUQloLoIds = Array.from(new Set(userQlos.map(({ learningObjectiveId }) => learningObjectiveId)));
      const userReadOnlyQloLoIds: Array<number> = [];
      const userQloLoIds: Array<number> = [];
      for (const loId of uniqueUQloLoIds) {
        const uQlos = userQlos.filter(({ learningObjectiveId }) => learningObjectiveId === loId);
        if (uQlos.length > 1) {
          // multiple copies of this QLO
          if (uQlos.find(({ userId }) => userId === user.id)) {
            // this user owns a copy of the QLO, so show it twice: once editable and once uneditable
            userReadOnlyQloLoIds.push(loId);
            userQloLoIds.push(loId);
          } else {
            // this user does not own a copy of the QLO so only show it under uneditable LOs
            userReadOnlyQloLoIds.push(loId);
          }
        } else {
          // only one copy of this QLO
          if (uQlos[0].userId === user.id) {
            // this user owns the copy, so show it under editable
            userQloLoIds.push(loId);
          } else {
            // this user does not own the copy, so only show it under uneditable
            userReadOnlyQloLoIds.push(loId);
          }
        }
      }
      setQuestionLos(questionLearningObjectives);
      setSelectedLoIds({
        questionLoIdsToRemove,
        selectedLoIds: userQloLoIds,
        selectedLoIdsChanged,
      });
      setTemplateReadOnlyLoIds(templateQloLoIds);
      setUserReadOnlyLoIds(userReadOnlyQloLoIds);
    })();
  });

  useEffect(() => {
    return () => {
      // set undefined to remove query param on component unload
      setStep(undefined);
    };
  }, [setStep]);

  // this useEffect updates some local state when the questionId changes. This is necessary when copying a question in place.
  useEffect(() => {
    // don't allow l8yId to be reset to false from an actual value
    if (initQuestionBuilder.l8yId !== false && initQuestionBuilder.questionId !== questionId) {
      setQuestionId(initQuestionBuilder.questionId);
      setEditingL8yId(initQuestionBuilder.l8yId);
      setIsCustomQuestion(mode === LibraryTypeEnum.User);
      setEditingGradingType(initQuestionBuilder.gradingType);
    }
  }, [editingL8yId, initQuestionBuilder, questionId, mode]);

  // This useEffect loads SAQ information about the question being edited to determine if students have started taking the question
  useEffectOnce(() => {
    async function loadQuestionUsageData(qId: number) {
      // load all assessmentQuestions for this question
      const assessmentQuestions = await apiNext.getAssessmentQuestionMapsByQuestionIds([qId]);

      // check if SAQs exist for any of the assessmentQuestions
      const getSaqs = await apiNext.getStudentAssessmentQuestionsByAssessmentQuestions(assessmentQuestions.map((aq) => aq.id));
      const startedAssessmentIds = getSaqs.reduce((acc, cur) => {
        const aqForSaq = assessmentQuestions.find((aq) => aq.id === cur.assessmentQuestionId);
        if (!!aqForSaq && !acc.includes(aqForSaq.assessmentId)) {
          acc.push(aqForSaq.assessmentId);
        }
        return acc;
      }, [] as Array<string>);

      // Load the assessments for the AQ ids that have SAQs.
      let startedAssessmentsWithQuestion: Array<AssessmentApiBase> = [];
      if (!!startedAssessmentIds.length) {
        startedAssessmentsWithQuestion = await apiNext.getAssessmentsByIds(startedAssessmentIds);
      }

      // check if any active courses contain these assessments and load them into state
      const questionUseData = startedAssessmentsWithQuestion.reduce((acc, assessment) => {
        const enrolledCourse = enrolledCourses.find((crs) => assessment.courseId === crs.id) as CourseApi;
        if (!isInThePast(enrolledCourse.endDate)) {
          acc.push({ courseName: enrolledCourse.name, courseId: enrolledCourse.id, assessmentName: assessment.name, assessmentId: assessment.id });
        }
        return acc;
      }, [] as Array<QuestionUsageData>);

      setQuestionUsageData(questionUseData);
      setIsLoadingQuestionUseData(false);
    }

    if (!questionId || !isCustomQuestion) {
      setIsLoadingQuestionUseData(false);
      return;
    }
    loadQuestionUsageData(questionId);
  });


  const handleCopyQuestion = async () => {
    if (!!handleQuestionAction && !!questionId) {
      setIsCopyingQuestion(true);
      const questionActionPayload = {
        questionId,
        assessmentId: editingAssessmentId,
      } as QuestionActionPayload;
      await handleQuestionAction(QuestionActionEnum.CopyQuestion, questionActionPayload);
      setIsCopyingQuestion(false);
      setQuestionUsageData(null);
    }
  };

  const confirmCancelMessage = {
    [QuestionBuilderStep.ChooseLO]: 'LO selection has been changed, are you sure you want to close?',
    [QuestionBuilderStep.Authoring]: 'Question data has been changed, are you sure you want to close?',
    [QuestionBuilderStep.Reviewing]: 'Details have been changed, are you sure you want to close?',
    [QuestionBuilderStep.Close]: '',
  };

  const handleL8yAuthorStatusChange = (nextStatus: L8yAuthorStatus) => {
    console.debug(`:: l8yAuthorStatus from ${l8yAuthorStatus} to ${nextStatus}`);
    return setL8yAuthorStatus(nextStatus);
  };

  const handleCancel = () => {
    const confirmationInit = {
      confirmationType: ConfirmationTypeEnum.Warn,
      title: 'Cancel with Unsaved Changes?',
      message: confirmCancelMessage[step],
      onConfirm: handleClose,
      onCancel: () => {},
    };
    switch (step) {
      case QuestionBuilderStep.ChooseLO: {
        if (!!selectedLoIds.length && selectedLoIdsChanged) {
          return triggerConfirmationPrompt(confirmationInit);
        }
        break;
      }
      case QuestionBuilderStep.Authoring: {
        if (shouldWarnOnCancel) {
          return triggerConfirmationPrompt(confirmationInit);
        }
        break;
      }
      case QuestionBuilderStep.Reviewing: {
        if (reviewingStateChanged) {
          return triggerConfirmationPrompt(confirmationInit);
        }
        break;
      }
    }
    handleClose();
  };

  const handleLoChange = (action: AddRemoveEnum, loId: number) => {
    const { id: qLoId } = questionLos.find(({ learningObjectiveId, userId }) => learningObjectiveId === loId && userId === user.id) || {};
    const compareSelectedLoIds = (initial: Array<number>, selected: Array<number>) => {
      const copyOfInitial: Array<number> = JSON.parse(JSON.stringify(initial));
      const copyOfSelected: Array<number> = JSON.parse(JSON.stringify(selected));
      copyOfInitial.sort((a, b) => a - b);
      copyOfSelected.sort((a, b) => a - b);
      return JSON.stringify(copyOfInitial) !== JSON.stringify(copyOfSelected);
    };
    switch (action) {
      case AddRemoveEnum.Add: {
        setSelectedLoIds((prev) => {
          // if LO has been removed and then re-added nix it from the qlo hit list
          const addedQuestionOnRemoveList = !!qLoId && prev.questionLoIdsToRemove.includes(qLoId);
          const updatedSelectedLoIds = [...prev.selectedLoIds, loId];
          return {
            questionLoIdsToRemove: addedQuestionOnRemoveList
              ? questionLoIdsToRemove.filter((q) => qLoId !== q)
              : prev.questionLoIdsToRemove,
            selectedLoIds: updatedSelectedLoIds,
            selectedLoIdsChanged: compareSelectedLoIds(selectedLoIds, updatedSelectedLoIds),
          };
        });
        break;
      }
      case AddRemoveEnum.Remove: {
        setSelectedLoIds((prev) => {
          const updatedSelectedLoIds = selectedLoIds.filter((id) => id !== loId);
          return {
            ...prev,
            selectedLoIds: updatedSelectedLoIds,
            questionLoIdsToRemove: !!qLoId
              ? [...prev.questionLoIdsToRemove, qLoId]
              : prev.questionLoIdsToRemove,
            selectedLoIdsChanged: compareSelectedLoIds(selectedLoIds, updatedSelectedLoIds),
          };
        });
        break;
      }
    }
  };

  const handleLoSave = async (shouldClose?: boolean) => {
    if (!editingExistingQuestion) {
      throw new Error('Trying to save LOs on non-existent question');
    }
    if (isCustomQuestion) {
      // pass both the set of question LOs and qLoIds for atomic removal
      const updatedUserQuestionLos = await dispatch(updateUserQuestionLosForUserQuestion(editingL8yId, selectedLoIds, questionLoIdsToRemove));
      console.debug('updatedUserQuestionLos', updatedUserQuestionLos);
    } else {
      const { id: templateQuestionId } = templateQuestions.find((uq) => uq.l8yId === editingL8yId) || {};
      !!templateQuestionId && dispatch(updateUserQuestionLosForTemplateQuestion(templateQuestionId, selectedLoIds, questionLoIdsToRemove));
    }
    if (shouldClose) {
      return handleClose();
    }
    setSelectedLoIds((prev) => ({ ...prev, selectedLoIdsChanged: false, questionLoIdsToRemove: [] }));
    return setStep(QuestionBuilderStep.Authoring);
  };

  const handleAddToAssessment = async (result: unknown) => {
    const { payload } = result as { payload: { id: number } };
    if (!handleQuestionAction) {
      return false;
    }

    if (!!payload.id && !!editingAssessmentId) {
      await handleQuestionAction(QuestionActionEnum.AddQuestion, {
        questionId: payload.id,
        assessmentId: editingAssessmentId,
      });
    }
  };

  const handleL8yAuthoringSave = async (
    l8yAuthoringSaveCallback: (() => Promise<AuthorAppGetItem | null>) | undefined, // this is explicitly allowed to be undefined so we can centralize the existence check of the function
    targetStep?: QuestionBuilderStep,
    addQuestionToAssessment?: boolean
  ) => {
    if (!l8yAuthoringSaveCallback) {
      console.error('l8yAuthoringSaveCallback is undefined');
      return;
    }
    setIsSaving(true);
    const itemData = await l8yAuthoringSaveCallback();
    if (!itemData) {
      throw new Error('itemData not returned');
    }
    let result: unknown;
    if (editingExistingQuestion) {
      const editedQuestion = {
        l8yId: editingL8yId,
        questionUse: editingQuestionUse,
        shortTitle: editingShortTitle,
        blooms: editingBlooms,
        gradingType: editingGradingType,
      };
      result = await dispatch(updateUserQuestion(user.id, editedQuestion, selectedLoIds, questionLoIdsToRemove, itemData));
    } else {
      result = await dispatch(createUserQuestion(user.id, itemData, selectedLoIds, editingBlooms, editingQuestionUse));
    }

    setIsSaving(false);
    const { error, message } = result as { error: string; message: string };
    if (error) {
      console.warn('createOrUpdateQuestion error', error, message);
      setErrorMessage(message);
      handleL8yAuthorStatusChange(L8yAuthorStatus.HomeEditError);
      setShouldWarnOnCancel(true);
    } else {
      // update questionId on save, questionId only ever changes (from false to id) on l8y save of new question
      const { payload } = result as { payload: { id: number } };
      setQuestionId(payload.id);
      setErrorMessage('');
      if (!editingL8yId) {
        setEditingL8yId(itemData.item.reference);
      }
      if (addQuestionToAssessment) {
        await handleAddToAssessment(result);
      }
      setShouldWarnOnCancel(false);
      if (targetStep === QuestionBuilderStep.Close) {
        return handleClose();
      }
      setItemJson(itemData);
      if (!targetStep) {
        return;
      }
      return setStep(targetStep);
    }
  };

  const handleReviewingSave = async (targetStep: QuestionBuilderStep, addQuestionToAssessment = false) => {
    if (editingExistingQuestion && !!itemJson) {
      setIsSaving(true);
      const shortTitle = editingQuestionUse === QuestionUseEnum.Readiness
        ? editingShortTitle
        : null;
      const editedQuestion = {
        blooms: editingBlooms,
        l8yId: editingL8yId,
        questionUse: editingQuestionUse,
        shortTitle,
        gradingType: editingGradingType,
      };
      const result: unknown = await dispatch(updateUserQuestion(user.id, editedQuestion, selectedLoIds, questionLoIdsToRemove, itemJson));

      const { error, message } = result as { error: string; message: string };
      if (error) {
        console.warn('createOrUpdateQuestion error', error, message);
        setErrorMessage(message);
      } else {
        setErrorMessage('');
        setReviewingState({ reviewingStateChanged: false });
        if (addQuestionToAssessment) {
          await handleAddToAssessment(result);
        }
        setIsSaving(false);
        if (targetStep === QuestionBuilderStep.Close) {
          return handleClose();
        }
        return setStep(targetStep);
      }
      setIsSaving(false);
    }
  };

  const handleL8yEvent = (event: OnL8yAuthorEvent, eventData: unknown, route: string, safeToUnload: boolean) => {
    // The l8y event handler CANNOT respond to external state.
    console.debug(':: handleL8yEvent', event, eventData, route, safeToUnload);
    switch (event) {
      case OnL8yAuthorEvent.Save: {
        // if save from widgets, home dirty
        if ([L8yAuthorRoute.WidgetsNew, L8yAuthorRoute.WidgetsReference].includes(route as L8yAuthorRoute)) {
          handleL8yAuthorStatusChange(L8yAuthorStatus.HomeEditDirty);
        }
        break;
      }
      case OnL8yAuthorEvent.SaveSuccess: {
        setIsSaving(false);
        handleL8yAuthorStatusChange(L8yAuthorStatus.HomeEditClean);
        break;
      }
      case OnL8yAuthorEvent.ItemEditChanged: {
        // this only fires when the title changes for some reason
        if (route === L8yAuthorRoute.ItemsReference) {
          handleL8yAuthorStatusChange(L8yAuthorStatus.HomeEditDirty);
        } else if (route === L8yAuthorRoute.ItemsNew) {
          handleL8yAuthorStatusChange(L8yAuthorStatus.HomeNewDirty);
        }
        setShouldWarnOnCancel(true);
        break;
      }
      case OnL8yAuthorEvent.RenderItem: {
        if (route === L8yAuthorRoute.ItemsReference) {
          handleL8yAuthorStatusChange(safeToUnload ? L8yAuthorStatus.HomeEditClean : L8yAuthorStatus.HomeEditDirty);
        }
        break;
      }
      case OnL8yAuthorEvent.WidgetEditPreviewChanged: {
        if (route === L8yAuthorRoute.WidgetsNew) {
          handleL8yAuthorStatusChange(L8yAuthorStatus.WidgetNewDirty);
        } else if (route === L8yAuthorRoute.WidgetsReference) {
          handleL8yAuthorStatusChange(L8yAuthorStatus.WidgetEditDirty);
        }
        break;
      }
      case OnL8yAuthorEvent.WidgetEditEditorReady: {
        if (route === L8yAuthorRoute.WidgetsNew) {
          handleL8yAuthorStatusChange(L8yAuthorStatus.WidgetChooseFeature);
        }
        break;
      }
      case OnL8yAuthorEvent.WidgetEditWidgetReady: {
        // if widget editor has loaded, warn on cancel before final save
        setShouldWarnOnCancel(true);
        if (route === L8yAuthorRoute.WidgetsNew) {
          handleL8yAuthorStatusChange(L8yAuthorStatus.WidgetNewDirty);
        } else if (route === L8yAuthorRoute.WidgetsReference) {
          handleL8yAuthorStatusChange(L8yAuthorStatus.WidgetEditDirty);
        }
        break;
      }
    }
  };

  const hasSelectedLos = !!selectedLoIds.length || !!templateReadOnlyLoIds.length || !!userReadOnlyLoIds.length;
  const renderFooter = (l8yAuthoringSaveCallback?: (() => Promise<AuthorAppGetItem | null>) | undefined) => {
    const hasError = errorMessage !== '';
    const disableButtons = hasError || isSaving;
    const buttonStateMachine: QuestionBuilderFooterObject = {
      [QuestionBuilderStep.Close]: { leftButtons: [], rightButtons: [] },
      [QuestionBuilderStep.ChooseLO]: {
        leftButtons: [
          {
            alias: ButtonAlias.Cancel,
            label: 'Cancel',
            onClick: handleCancel,
          },
        ],
        rightButtons: [
          {
            alias: ButtonAlias.Done,
            buttonType: isCustomQuestion ? ButtonTypeEnum.Secondary : ButtonTypeEnum.Primary,
            disabled: !hasSelectedLos,
            label: 'Done',
            onClick: handleClose,
            show: editingExistingQuestion && !selectedLoIdsChanged,
          },
          {
            alias: ButtonAlias.SaveAndDone,
            buttonType: isCustomQuestion ? ButtonTypeEnum.Secondary : ButtonTypeEnum.Primary,
            disabled: !hasSelectedLos,
            label: 'Save & Exit',
            onClick: () => handleLoSave(true),
            show: editingExistingQuestion && selectedLoIdsChanged,
          },
          {
            alias: ButtonAlias.GoNextSurvey,
            buttonType: ButtonTypeEnum.Secondary,
            label: 'Create Survey Item',
            onClick: () => {
              setEditingGradingType(GradingTypeTag.Survey);
              setStep(QuestionBuilderStep.Authoring);
            },
            disabled: !hasSelectedLos,
            show: !editingExistingQuestion,
          },
          {
            alias: ButtonAlias.GoNext,
            buttonType: ButtonTypeEnum.Primary,
            label: 'Create Assessment Item',
            onClick: () => {
              setEditingGradingType(GradingTypeTag.Assessment);
              setStep(QuestionBuilderStep.Authoring);
            },
            disabled: !hasSelectedLos,
            show: !editingExistingQuestion,
          },
          {
            alias: ButtonAlias.SaveAndGoNext,
            buttonType: ButtonTypeEnum.Primary,
            label: 'Edit Question',
            onClick: () => handleLoSave(),
            disabled: !hasSelectedLos,
            show: editingExistingQuestion && isCustomQuestion,
          },
        ],
      },
      [QuestionBuilderStep.Authoring]: {
        leftButtons: [
          {
            alias: ButtonAlias.SaveAndGoBack,
            disabled: hasError,
            icon: () => <FaArrowLeft />,
            label: 'Save & Edit LOs',
            onClick: async () => handleL8yAuthoringSave(l8yAuthoringSaveCallback, QuestionBuilderStep.ChooseLO),
            show: [L8yAuthorStatus.HomeEditDirty].includes(l8yAuthorStatus),
          },
          {
            alias: ButtonAlias.GoBack,
            icon: () => <FaArrowLeft />,
            label: 'Edit LOs',
            onClick: () => setStep(QuestionBuilderStep.ChooseLO),
            show: [L8yAuthorStatus.HomeEditClean, L8yAuthorStatus.HomeNewClean].includes(l8yAuthorStatus),
          },
          {
            alias: ButtonAlias.Cancel,
            label: 'Cancel',
            onClick: () => handleCancel(),
            show: [L8yAuthorStatus.HomeEditDirty, L8yAuthorStatus.HomeNewDirty, L8yAuthorStatus.HomeEditError].includes(l8yAuthorStatus),
          },
        ],
        rightButtons: [
          {
            alias: ButtonAlias.Done,
            buttonType: ButtonTypeEnum.Secondary,
            iconAlign: LeftRightEnum.Right,
            label: 'Done',
            onClick: handleClose,
            show: [L8yAuthorStatus.HomeEditClean].includes(l8yAuthorStatus),
          },
          // show Add to Assessment if form is clean, show Save & Add if dirty
          {
            alias: ButtonAlias.SaveAndAdd,
            buttonType: ButtonTypeEnum.Loading,
            isLoading: isSaving,
            loadingText: 'Adding to Assessment…',
            disabled: disableButtons,
            icon: () => <FaPlus />,
            iconAlign: LeftRightEnum.Right,
            label: 'Add to Assessment',
            onClick: async () => handleL8yAuthoringSave(l8yAuthoringSaveCallback, QuestionBuilderStep.Close, true),
            show: [L8yAuthorStatus.HomeEditClean].includes(l8yAuthorStatus) && allowAdd,
          },
          {
            alias: ButtonAlias.SaveAndAdd,
            buttonType: ButtonTypeEnum.Loading,
            isLoading: isSaving,
            loadingText: 'Adding to Assessment…',
            disabled: disableButtons,
            icon: () => <FaPlus />,
            iconAlign: LeftRightEnum.Right,
            label: 'Save & Add to Assessment',
            onClick: async () => handleL8yAuthoringSave(l8yAuthoringSaveCallback, QuestionBuilderStep.Close, true),
            show: [L8yAuthorStatus.HomeEditDirty].includes(l8yAuthorStatus) && allowAdd,
          },
          {
            alias: ButtonAlias.SaveAndDone,
            label: 'Save & Exit',
            buttonType: ButtonTypeEnum.Loading,
            loadingText: 'Saving…',
            isLoading: isSaving,
            onClick: async () => handleL8yAuthoringSave(l8yAuthoringSaveCallback, QuestionBuilderStep.Close),
            show: !allowAdd && [L8yAuthorStatus.HomeEditDirty].includes(l8yAuthorStatus),
          },
          {
            alias: ButtonAlias.GoNext,
            buttonType: ButtonTypeEnum.Secondary,
            disabled: disableButtons,
            label: 'Add Details',
            icon: () => <FaArrowRight />,
            iconAlign: LeftRightEnum.Right,
            onClick: () => setStep(QuestionBuilderStep.Reviewing),
            show: [L8yAuthorStatus.HomeEditClean].includes(l8yAuthorStatus),
          },
          {
            alias: ButtonAlias.OnlySave,
            label: 'Save',
            disabled: [L8yAuthorStatus.WidgetChooseFeature].includes(l8yAuthorStatus),
            buttonType: ButtonTypeEnum.Loading,
            loadingText: 'Saving…',
            isLoading: isSaving,
            onClick: async () => handleL8yAuthoringSave(l8yAuthoringSaveCallback),
            show: [
              L8yAuthorStatus.WidgetNewDirty,
              L8yAuthorStatus.WidgetEditDirty,
              L8yAuthorStatus.WidgetChooseFeature,
            ].includes(l8yAuthorStatus),
          },
          {
            alias: ButtonAlias.SaveAndGoNext, // editing existing question & is changed
            buttonType: ButtonTypeEnum.Loading,
            isLoading: isSaving,
            label: 'Save & Add Details',
            loadingText: 'Saving…',
            onClick: async () => handleL8yAuthoringSave(l8yAuthoringSaveCallback, QuestionBuilderStep.Reviewing),
            show: [L8yAuthorStatus.HomeEditDirty].includes(l8yAuthorStatus),
          },
        ],
      },
      [QuestionBuilderStep.Reviewing]: {
        leftButtons: [
          {
            alias: ButtonAlias.GoBack,
            icon: () => <FaArrowLeft />,
            label: 'Save Details & Edit Question',
            onClick: () => handleReviewingSave(QuestionBuilderStep.Authoring),
            show: reviewingStateChanged,
          },
          {
            alias: ButtonAlias.GoBack,
            icon: () => <FaArrowLeft />,
            label: 'Edit Question',
            onClick: () => setStep(QuestionBuilderStep.Authoring),
            show: !reviewingStateChanged,
          },
          {
            alias: ButtonAlias.Cancel,
            label: 'Cancel',
            onClick: () => handleCancel(),
            show: reviewingStateChanged,
          },
        ],
        rightButtons: [
          {
            alias: ButtonAlias.SaveAndAdd,
            buttonType: ButtonTypeEnum.Primary,
            isLoading: isSaving,
            icon: () => <FaPlus />,
            iconAlign: LeftRightEnum.Right,
            label: `${reviewingStateChanged ? 'Save & ' : ''}Add to Assessment`,
            onClick: () => handleReviewingSave(QuestionBuilderStep.Close, true),
            show: !hasBeenStarted && !!editingAssessmentId && allowAdd,
          },
          {
            alias: ButtonAlias.Done,
            buttonType: ButtonTypeEnum.Secondary,
            label: 'Done',
            onClick: handleClose,
            show: !isSaving && !reviewingStateChanged,
          },
          {
            alias: ButtonAlias.SaveAndDone,
            buttonType: ButtonTypeEnum.Loading,
            label: 'Save & Exit',
            isLoading: isSaving,
            onClick: () => handleReviewingSave(QuestionBuilderStep.Close),
            show: reviewingStateChanged,
          },
        ],
      },
    };
    return (
      <QuestionBuilderFooter
        editingExistingQuestion={editingExistingQuestion}
        errorMsg={errorMessage}
        navButtons={buttonStateMachine[step]}
        step={step}
      />
    );
  };

  const mainPanel: Record<QuestionBuilderStep, ReactNode> = {
    [QuestionBuilderStep.Close]: (<></>),
    [QuestionBuilderStep.ChooseLO]: (
      <>
        <QuestionBuilderLoChooser
          availableLos={availableCourseLearningObjectivesForUser}
          selectedLoIds={selectedLoIds}
          templateReadOnlyLoIds={templateReadOnlyLoIds}
          userReadOnlyLoIds={userReadOnlyLoIds}
          handleLoChange={handleLoChange}
          questionUsageData={questionUsageData}
          setQuestionUsageData={setQuestionUsageData}
          handleCopyQuestion={handleCopyQuestion}
          handleClose={handleClose}
        />
        {renderFooter()}
      </>
    ),
    [QuestionBuilderStep.Authoring]: (
      <div
        className="question-builder__tab learnosity-author__wrap"
        data-author-status={l8yAuthorStatus}
        data-questionid={questionId}
      >
        <LearnosityAuthorContainer
          onL8yEvent={handleL8yEvent}
          gradingType={editingGradingType || GradingTypeTag.Assessment}
          reference={editingL8yId}
          renderFooter={renderFooter}
          setItemJson={setItemJson}
          subjectId={course.subjectId}
          user={user}
        />
      </div>
    ),
    [QuestionBuilderStep.Reviewing]: (
      <>
        <div className="question-builder__tab">
          <div>
            <b>Question Details</b>
          </div>
          <div className="row">
            <div className="h4">
              Question Title: {itemJson?.item.title}
            </div>
          </div>
          <div className="row">
            <div className="question-builder__select col-xs-6 col-sm-3">
              <label htmlFor="select-question-use">Question Use</label>
              <div id="select-question-use">
                <QuestionUseDropdown
                  selectedValue={editingQuestionUse}
                  onChange={(val: QuestionUseEnum) => setReviewingState({ editingQuestionUse: val })}
                />
              </div>
            </div>
            <div className="question-builder__select col-xs-6 col-sm-3">
              <label htmlFor="select-blooms">Bloom's Level (Optional)</label>
              <div id="select-blooms">
                <BloomsDropdown
                  selectedBlooms={editingBlooms}
                  onChange={(val: number | null) => setReviewingState({ editingBlooms: val })}
                />
              </div>
            </div>
            <div className="question-builder__input col-xs-12 col-sm-6">
              {editingQuestionUse === QuestionUseEnum.Readiness && (
                <>
                  <label htmlFor="short-title">Short Title (student-facing)</label>
                  <input
                    id="short-title"
                    type="text"
                    onChange={({ target: { value } }) => setReviewingState({ editingShortTitle: value })}
                    value={editingShortTitle || ''}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        {renderFooter()}
      </>
    ),

  };

  return (
    <div className="question-builder" data-step={step}>
      {isLoadingQuestionUseData || isCopyingQuestion ? (
        <LoadingSpinner />
      ) :
        mainPanel[step]
      }
    </div>
  );
}

QuestionBuilderController.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleQuestionAction: PropTypes.func.isRequired,
  initQuestionBuilder: PropTypes.object.isRequired,
  mode: PropTypes.oneOf(Object.values(LibraryTypeEnum)).isRequired,
};

export default QuestionBuilderController;
