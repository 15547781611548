/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { formatPlural, numberToText } from 'utils/commonFormattingFunctions';
import ConfirmationPromptContainer from 'shared-components/ConfirmationPrompt/ConfirmationPromptContainer';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import CountdownProgressBar from 'shared-components/ItemRemovalProgressMeter/ItemRemovalProgressMeter';
import { ConfirmationTypeEnum } from 'types/common.types';
import { AssessmentApiBase } from 'types/backend/assessments.types';
import { CourseAssessmentPresetApi } from 'types/backend/courseAssessmentPresets.types';
import { PresetHasChanged } from '../../CourseDetailsController.types';
import './ApplyPresetsToExistingConfirmation.scss';

enum ConfirmationStepEnum {
  ShowLoading = 'show-loading',
  ShowConfirmation = 'show-confirmation',
  ShowCompletion = 'show-completion',
}

interface ApplyPresetsToExistingProps {
  assessmentsToUpdate: Array<AssessmentApiBase> | null
  resetApplyToExistingAssessmentsState: () => void
  startedFutureAssessmentCount: number | null
  updatePresetAndAssessments: () => void
  updatedItemCount: number
  presetChanges: PresetHasChanged<CourseAssessmentPresetApi> | null
}

export default function ApplyPresetsToExistingAssessmentsConfirmation(props: ApplyPresetsToExistingProps) {
  const {
    assessmentsToUpdate,
    presetChanges,
    resetApplyToExistingAssessmentsState,
    startedFutureAssessmentCount,
    updatePresetAndAssessments,
    updatedItemCount,
  } = props;

  // local state
  const [confirmationStep, setConfirmationStep] = useState<ConfirmationStepEnum>(ConfirmationStepEnum.ShowLoading);

  // move the step forward when assessment data is loaded
  useEffect(() => {
    if (startedFutureAssessmentCount !== null && Array.isArray(assessmentsToUpdate)) {
      setConfirmationStep(ConfirmationStepEnum.ShowConfirmation);
    }
  }, [startedFutureAssessmentCount, assessmentsToUpdate]);

  const handleUpdatePresetAndAssessments = () => {
    setConfirmationStep(ConfirmationStepEnum.ShowCompletion);
    updatePresetAndAssessments();
  };

  const changedValues = (() => {
    const changedValuesArray = [] as Array<string>;
    if (presetChanges === null) {
      return changedValuesArray;
    }
    if (presetChanges.preclassGradingPolicyHasChanged || presetChanges.preclassFreeAttemptsHasChanged || presetChanges.preclassPointPenaltyHasChanged) {
      changedValuesArray.push('Readiness/Pre-class Grading Policy');
    }
    if (presetChanges.preclassOpenDateOffsetHasChanged) {
      changedValuesArray.push('Readiness/Pre-class Open Date Offset');
    }
    if (presetChanges.preclassOpenTimeHasChanged) {
      changedValuesArray.push('Readiness/Pre-class Open Time');
    }
    if (presetChanges.preclassDueTimeHasChanged) {
      changedValuesArray.push('Readiness/Pre-class Due Time (also affects late date on assessments with a late policy)');
    }
    if (presetChanges.preclassLatePolicyHasChanged) {
      changedValuesArray.push('Readiness/Pre-class Late Policy');
    }
    if (presetChanges.preclassLateDateOffsetHasChanged) {
      changedValuesArray.push('Readiness/Pre-class Late Date Offset');
    }
    if (presetChanges.preclassLatePenaltyHasChanged) {
      changedValuesArray.push('Readiness/Pre-class Late Penalty');
    }
    if (presetChanges.homeworkFreeAttemptsHasChanged || presetChanges.homeworkGradingPolicyHasChanged || presetChanges.homeworkPointPenaltyHasChanged) {
      changedValuesArray.push('Homework Grading Policy');
    }
    if (presetChanges.homeworkOpenDateOffsetHasChanged) {
      changedValuesArray.push('Homework Open Date Offset');
    }
    if (presetChanges.homeworkOpenTimeHasChanged) {
      changedValuesArray.push('Homework Open Time');
    }
    if (presetChanges.homeworkDueTimeHasChanged) {
      changedValuesArray.push('Homework Due Time (also affects late date on assessments with a late policy)');
    }
    if (presetChanges.homeworkLatePolicyHasChanged) {
      changedValuesArray.push('Homework Late Policy');
    }
    if (presetChanges.homeworkLateDateOffsetHasChanged) {
      changedValuesArray.push('Homework Late Date Offset');
    }
    if (presetChanges.homeworkLatePenaltyHasChanged) {
      changedValuesArray.push('Homework Late Penalty');
    }
    if (presetChanges.studyPathPrepFreeAttemptsHasChanged || presetChanges.studyPathPrepGradingPolicyHasChanged || presetChanges.studyPathPrepPointPenaltyHasChanged) {
      changedValuesArray.push('Study Path Prep Questions Grading Policy');
    }
    if (presetChanges.studyPathPracticeGradingPolicyHasChanged || presetChanges.studyPathPracticeFreeAttemptsHasChanged || presetChanges.studyPathPracticePointPenaltyHasChanged) {
      changedValuesArray.push('Study Path Practice Test Grading Policy');
    }
    if (presetChanges.studyPathDueTimeHasChanged) {
      changedValuesArray.push('Study Path Due Time (also affects late date of prep questions and practice test if they have a late policy)');
    }
    if (presetChanges.studyPathPrepLatePolicyHasChanged) {
      changedValuesArray.push('Study Path Prep Questions Late Policy');
    }
    if (presetChanges.studyPathPrepLateDateOffsetHasChanged) {
      changedValuesArray.push('Study Path Prep Questions Late Date Offset');
    }
    if (presetChanges.studyPathPrepLatePenaltyHasChanged) {
      changedValuesArray.push('Study Path Prep Questions Late Penalty');
    }
    if (presetChanges.studyPathPracticeLatePolicyHasChanged) {
      changedValuesArray.push('Study Path Practice Test Late Policy');
    }
    if (presetChanges.studyPathPracticeLateDateOffsetHasChanged) {
      changedValuesArray.push('Study Path Practice Test Late Date Offset');
    }
    if (presetChanges.studyPathPracticeLatePenaltyHasChanged) {
      changedValuesArray.push('Study Path Practice Test Late Penalty');
    }
    return changedValuesArray;
  })();

  switch (confirmationStep) {
    case ConfirmationStepEnum.ShowLoading: {
      return (
        <ConfirmationPromptContainer
          confirmationType={ConfirmationTypeEnum.Warn}
          title="Apply preset changes to existing assessments"
          handleCancel={resetApplyToExistingAssessmentsState}
        >
          <div className="remove-lo-with-questions__container">
            <div className="remove-lo-with-questions__message">
              loading related student assessment data ...
            </div>
          </div>
        </ConfirmationPromptContainer>
      );
    }
    case ConfirmationStepEnum.ShowConfirmation: {
      if (!assessmentsToUpdate || startedFutureAssessmentCount === null) {
        return (
          <div>Error loading assessment data, please try again</div>
        );
      }
      return (
        <ConfirmationPromptContainer
          confirmationType={ConfirmationTypeEnum.Warn}
          title="Apply preset changes to existing assessments"
          handleCancel={resetApplyToExistingAssessmentsState}
        >
          <div className="apply-presets-to-existing__container">
            {!!changedValues.length && (
              <div className="apply-presets-to-existing__message">
                The following {formatPlural('value', changedValues.length)} will be changed:
                <ul>
                  {changedValues.map((changedValue) => {
                    return (
                      <li key={changedValue}>{changedValue}</li>
                    );
                  })}
                </ul>
              </div>
            )}
            {startedFutureAssessmentCount > 0 && (
              <div className="apply-presets-to-existing__message">
                {numberToText(startedFutureAssessmentCount, true)} future {formatPlural('assessment', startedFutureAssessmentCount)} {formatPlural('have', startedFutureAssessmentCount)} already been started by students and will not be affected by this action.
              </div>
            )}
            <div className="apply-presets-to-existing__message">
              {!!assessmentsToUpdate.length ?
                (
                  <>
                    {numberToText(assessmentsToUpdate.length, true)} future {formatPlural('assessment', assessmentsToUpdate.length)} will be affected:
                    <ul>
                      {assessmentsToUpdate.map((assessment) => {
                        return (
                          <li key={assessment.id}>{assessment.name}</li>
                        );
                      })}
                    </ul>
                  </>
                ) : (
                  <>
                    There are no future assessments which will be affected by this action.
                  </>
                )}
            </div>
            <BetterButton
              primary
              onClick={handleUpdatePresetAndAssessments}
              text="Save preset changes"
              autoFocus
            />
            <div className="apply-presets-to-existing__spacer" />
            <BetterButton
              secondary
              onClick={resetApplyToExistingAssessmentsState}
              text="Cancel"
            />
          </div>
        </ConfirmationPromptContainer>
      );
    }
    case ConfirmationStepEnum.ShowCompletion: {
      const totalItemCount = assessmentsToUpdate ? assessmentsToUpdate.length : 0; // The preset itself is not included in the count
      return (
        <ConfirmationPromptContainer
          confirmationType={ConfirmationTypeEnum.Alert}
          title="Making changes..."
        >
          <CountdownProgressBar
            totalItemCount={totalItemCount}
            removedItemCount={updatedItemCount}
          />
        </ConfirmationPromptContainer>
      );
    }
  }
}

ApplyPresetsToExistingAssessmentsConfirmation.propTypes = {
  assessmentsToUpdate: PropTypes.array,
  startedFutureAssessmentCount: PropTypes.number || null,
  resetApplyToExistingAssessmentsState: PropTypes.func.isRequired,
  updatePresetAndAssessments: PropTypes.func.isRequired,
  updatedItemCount: PropTypes.number,
  presetChanges: PropTypes.object,
};


