import React, { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import {
  useRouteMatch,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { FaMask } from 'react-icons/fa';

import useClassSessionQuery from 'hooks/useClassSessionQuery';
import { validUuid } from 'utils';
import { idleTimerConfig } from 'utils/sharedConfig';
import { useAppDispatch, useAppSelector } from 'store';
import activeSlice from 'store/slices/active';
import getInitialData from 'store/actions/getInitialData';
import retrieveEnrichedCurrentClassSession from 'store/selectors/retrieveEnrichedCurrentClassSession';
import AssessmentBuilderController from './AssessmentBuilderController/AssessmentBuilderController';
import AssessmentsListController from './AssessmentsListController/AssessmentsListController';
import CourseDetailsController from './CourseDetailsController/CourseDetailsController';
import LoadingSpinner from 'shared-components/Spinner/LoadingSpinner';
import CourseHeaderNav from 'shared-components/CourseHeaderNav/CourseHeaderNav';
import AccessController from 'shared-components/AccessController/AccessController';
import CourseLoSelectorController from './CourseLoSelectorController/CourseLoSelectorController';
import CoursePlannerController from './CoursePlannerController/CoursePlannerController';
import BetterCoursePlannerController from './BetterCoursePlannerController/BetterCoursePlannerController';
import DailyPlannerController from './DailyPlannerController/DailyPlannerController';
import DevUtils from 'utils-dev/DevUtils';
import InstructionsController from './InstructionsController/InstructionsController';
import SingleQADashboardController from './SingleQADashboardController/SingleQADashboardController';
import StudentScoresController from './StudentScoresController/StudentScoresController';
import TitledRoute from 'utils/TitledRoute';
import { InstructorCoursePath } from 'types/instructor.types';
import { InstructorRoles } from 'types/backend/roles.types';
import './Course.scss';

const minutesIdleUntilReload = Number(process.env.REACT_APP_MINUTES_IDLE_UNTIL_SYNC) || 60;

export default function Course() {
  const { path, params }: { path: string; params: { id: string }} = useRouteMatch();
  const { id: courseId } = params as { id: string };
  const courseParamValid = validUuid(courseId);
  const dispatch = useAppDispatch();
  const currentClassSession = useAppSelector(retrieveEnrichedCurrentClassSession);
  const adminUser = useAppSelector((store) => store.adminUser);
  const course = useAppSelector((store) => store.active.course);
  const passiveLoading = useAppSelector((store) => store.passive.loading);
  const passiveInstructorCourses = useAppSelector((store) => store.passive.instructorCourses);
  const classSessions = useAppSelector((store) => store.active.classSessions);
  const [selectedClassSessionId] = useClassSessionQuery(classSessions);

  //clean active state out when the user exits the course
  useEffect(() => {
    return () => {
      console.debug('Leaving course, clear active state');
      dispatch(activeSlice.actions.clear());
    };
  }, [dispatch]);

  /**
   * Update activeClassSession when there is a new `class-session=` query param
   */
  useEffect(() => {
    if (currentClassSession?.id !== selectedClassSessionId) {
      const updatedClassSession = classSessions.find((cs) => cs.id === selectedClassSessionId);
      console.debug('update active class session', selectedClassSessionId, updatedClassSession);
      !!updatedClassSession && dispatch(activeSlice.actions.setActiveCurrentClassSession(updatedClassSession));
    }
  }, [classSessions, currentClassSession, dispatch, selectedClassSessionId]);

  /*
   * Course Specific Data Fetching Effect
   *
   * When our course id from the url route changes, we
   * generate/fetch all the 'active' data for that course
   * and dispatch it to the store.
   *
   * this needs to re-run whenever any passive data changes
   *
   */
  useEffect(() => {
    // wait to getInitialData until passiveCourses has been initiated in instructor Base
    if (!passiveLoading && !!passiveInstructorCourses?.length) {
      const courseNotLoaded = !course?.id;
      const courseIdParamDiffersFromState = courseParamValid && courseId !== course?.id;
      if (courseNotLoaded || courseIdParamDiffersFromState) {
        dispatch(getInitialData(courseId));
      }
    } else {
      console.warn('passiveInstructorCourses empty, do not getInitialData yet');
    }
  }, [course, courseId, courseParamValid, dispatch, passiveInstructorCourses, passiveLoading]);

  const { name: courseName } = course || {};
  const leftTabs = [
    {
      id: 'instructor-nav-item__daily-planner',
      label: 'Daily Planner',
      coursePath: InstructorCoursePath.DailyPlanner,
    },
    {
      id: 'instructor-nav-item__course-planner',
      label: 'Course Planner',
      coursePath: InstructorCoursePath.BetterCoursePlanner,
    },
    {
      id: 'instructor-nav-item__assessments-list',
      label: 'Assessments',
      coursePath: InstructorCoursePath.AssessmentsList,
    },
    {
      id: 'instructor-nav-item__student-performance',
      label: 'Student Performance',
      coursePath: InstructorCoursePath.StudentPerformance,
    },
    {
      id: 'instructor-nav-item__qa-dashboard',
      label: 'QA Dashboard',
      icon: <FaMask />,
      coursePath: InstructorCoursePath.AdminQADashboard,
      show: !!adminUser,
    },
  ];

  /**
   * Get latest course data when returning from idle
   * TODO CA-3083: I think eventually we should use this more tactically
   **/
  const onActive = async () => {
    const idleForMilliseconds = getIdleTime();
    const minutesIdle = Math.round(idleForMilliseconds / 1000 / 60);
    if (minutesIdle >= minutesIdleUntilReload) {
      console.info(`returning from idle after ${minutesIdle} minutes, refreshing data from server`);
      dispatch(getInitialData(courseId));
    }
    reset();
  };
  const { getIdleTime, reset } = useIdleTimer({
    ...idleTimerConfig,
    name: 'idle-timer_instructor-update',
    onActive,
  });

  if (!courseParamValid) {
    throw new Error(`Invalid Course Id in URL ${courseId}`);
  }
  if (courseId !== course?.id) {
    return <LoadingSpinner id="instructor-course-loading" loadingMessage="Loading Course" />;
  }

  return (
    <div className="course-page course-page__instructor">
      <CourseHeaderNav
        leftTabs={leftTabs}
        rightTabs={[
          {
            id: 'instructor-nav-item__access-controller',
            label: 'Course Access',
            coursePath: InstructorCoursePath.CourseAccess,
          },
        ]}
        linkPrefix={`/instructor/course/${courseId}/`}
        userRole={InstructorRoles.Instructor}
        title={courseName}
      />
      <Switch>
        <Route exact path={`${path}/${InstructorCoursePath.DevUtils}`}><DevUtils/></Route>
        <TitledRoute
          exact
          path={`${path}/edit`}
          title="Course Details"
        >
          <CourseDetailsController type="edit" />
        </TitledRoute>
        <TitledRoute
          exact
          path={`${path}/${InstructorCoursePath.DailyPlanner}`}
          component={DailyPlannerController}
          title="Daily Planner"
        />
        <TitledRoute
          exact
          path={`${path}/${InstructorCoursePath.BetterCoursePlanner}`}
          component={BetterCoursePlannerController}
          title="Enhanced Course Planner"
        />
        <TitledRoute
          exact
          path={`${path}/${InstructorCoursePath.SelectLearningObjectives}`}
          component={CourseLoSelectorController}
          title="Select Learning Objectives"
        />
        <TitledRoute
          exact
          path={`${path}/${InstructorCoursePath.Instructions}`}
          component={InstructionsController}
          title="Learning Resource Manager"
        />
        <TitledRoute
          exact
          path={`${path}/${InstructorCoursePath.AssessmentBuilder}`}
          component={AssessmentBuilderController}
          title="Assessment Builder"
        />
        <TitledRoute
          exact
          path={`${path}/${InstructorCoursePath.CoursePlanner}`}
          component={CoursePlannerController}
          title="Course Planner"
        />
        <TitledRoute
          exact
          path={`${path}/${InstructorCoursePath.AssessmentsList}`}
          component={AssessmentsListController}
          title="Assessments List"
        />
        <TitledRoute
          exact
          path={`${path}/${InstructorCoursePath.StudentPerformance}`}
          component={StudentScoresController}
          title="Student Performance"
        />
        <TitledRoute
          exact
          path={`${path}/${InstructorCoursePath.AdminQADashboard}`}
          component={SingleQADashboardController}
          title="Admin QA Dashboard"
        />
        <TitledRoute
          exact
          path={`${path}/${InstructorCoursePath.CourseAccess}`}
          component={AccessController}
          title="Course Access"
        />
        <Redirect to={`${path}/${InstructorCoursePath.DailyPlanner}`} />
      </Switch>
    </div>
  );
}
