import { LibraryTypeEnum } from 'types/backend/shared.types';
import { ClassSessionTopicApi } from 'types/backend/classSessionTopics.types';
import { ClassSessionLearningObjectiveApi } from 'types/backend/classSessionLearningObjectives.types';
import { TopicApi } from 'types/backend/topics.types';
import { LearningObjectiveApi } from 'types/backend/learningObjectives.types';


//this function assumes the topics are correctly ordered initially, which they should be
export function getPreviousIdForUserTopic(topic: TopicApi, topics: Array<TopicApi>) {
  const previousIdTopics = topics.filter(
    (t) =>
      t.unitId === topic.unitId &&
      t.type === LibraryTypeEnum.User &&
      t.userId === topic.userId
  );
  const editedIndex = previousIdTopics.findIndex((t) => t.id === topic.id);
  const previousId = editedIndex ? previousIdTopics[editedIndex - 1].id : 0;
  return previousId;
}

//this function returns true if the topic can be deleted from the class session
export function classSessionTopicCanBeDeleted(
  cstId: number,
  classSessionTopics: Array<ClassSessionTopicApi>,
  classSessionLearningObjectives: Array<ClassSessionLearningObjectiveApi>
) {
  const cst = classSessionTopics.find((c) => c.id === cstId) as ClassSessionTopicApi;
  const topicHasNoLos = classSessionLearningObjectives.findIndex((cslo) => cslo.topicId === cst.topicId && cslo.classSessionId === cst.classSessionId) === -1;
  return topicHasNoLos;
}

// this function navigates cslos to find the template los that may be associated with user topics. Necessary for proper filter display in LOSelector and CoursePlanner
export function getTemplateLosForUserTopics(
  customTopicIds: Array<number>,
  classSessionLearningObjectives: Array<ClassSessionLearningObjectiveApi>,
  availableLearningObjectives: Array<LearningObjectiveApi>
) {
  const customTopicTemplateLos = customTopicIds.reduce((acc, cur) => {
    const loIdsForTopic = classSessionLearningObjectives.reduce((accumulated, current) => {
      if (current.topicId === cur) {
        accumulated.push(current.learningObjectiveId);
      }
      return accumulated;
    }, [] as Array<number>);
    const losForTopic = availableLearningObjectives.filter(tlo => loIdsForTopic.includes(tlo.id) && tlo.type === LibraryTypeEnum.Template);
    const updatedLosForTopic = losForTopic.map(lo => ({ ...lo, topicId: cur }));
    acc = [...acc, ...updatedLosForTopic];
    return acc;
  }, [] as Array<LearningObjectiveApi>);
  return customTopicTemplateLos;
}

