import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import useIdleLogout, { IdleLogoutInstance } from 'hooks/useIdleLogout';
import Home from 'admin/controllers/Home/Home';
import GlobalNav from 'shared-components/GlobalNav/GlobalNav';
import CourseHeaderNav from 'shared-components/CourseHeaderNav/CourseHeaderNav';
import ErrorBoundary from 'shared-components/ErrorBoundary/ErrorBoundary';
import UsersController from 'admin/controllers/UsersController/UsersController';
import DuplicateCourses from 'admin/controllers/DuplicateCourses/DuplicateCourses';
import SystemReports from 'admin/controllers/SystemReports/SystemReports';
import MultiQADashboard from 'admin/controllers/MultiQADashboard/MultiQADashboard';
import CourseActivityCheck from 'admin/controllers/CourseActivityCheck/CourseActivityCheck';
import SystemNotification from 'shared-components/ToasterNotification/SystemNotification';
import { AdminPathEnum } from 'types/admin.types';
import { Store } from 'types/store.types';
const ContentLibrary = React.lazy(() => import('./controllers/ContentLibrary/ContentLibrary'));
const SurveyDistributor = React.lazy(() => import('./controllers/SurveyDistributor/SurveyDistributor'));

export default function AdminBase() {
  const { path } = useRouteMatch();
  const user = useSelector((store: Store) => store.user);
  useIdleLogout(user, IdleLogoutInstance.Admin, 48);

  return (
    <main className="admin-base">
      <div className="app_top">
        <GlobalNav isInstructor={false} isAdmin userData={user} />
        <SystemNotification />
      </div>
      <div className="app__base">
        <CourseHeaderNav
          leftTabs={[
            {
              id: 'admin-nav-item__home',
              label: 'Home',
              coursePath: `/admin/${AdminPathEnum.Home}`,
              exact: true,
            },
            {
              id: 'admin-nav-item__users',
              label: 'Users',
              coursePath: `/admin/${AdminPathEnum.Users}`,
            },
            {
              id: 'admin-nav-item__duplicate',
              label: 'Duplicate Courses',
              coursePath: `/admin/${AdminPathEnum.DuplicateCourses}`,
            },
            {
              id: 'admin-nav-item__qa-dashboard',
              label: 'QA Dashboard',
              coursePath: `/admin/${AdminPathEnum.QADashboard}`,
            },
            {
              id: 'admin-nav-item__system-reports',
              label: 'System Reports',
              coursePath: `/admin/${AdminPathEnum.SystemReports}`,
              show: !!window.localStorage.getItem('CODON_SUPER_ADMIN'),
            },
            {
              id: 'admin-nav-item__course-activity-check',
              label: 'Course Activity Check',
              coursePath: `/admin/${AdminPathEnum.CourseActivityCheck}`,
            },
            {
              id: 'admin-nav-item__library',
              label: 'Content Library',
              coursePath: `/admin/${AdminPathEnum.ContentLibrary}`,
            },
            {
              id: 'admin-nav-item__survey-distributor',
              label: 'Survey Distributor',
              coursePath: `/admin/${AdminPathEnum.SurveyDistributor}`,
            },
          ]}
          title="Welcome Admin"
        />
        <ErrorBoundary isAdmin>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route exact path={[path, `${path}/${AdminPathEnum.Home}`]}>
                <Home/>
              </Route>
              <Route exact path={`${path}/${AdminPathEnum.Users}`}>
                <UsersController />
              </Route>
              <Route exact path={`${path}/${AdminPathEnum.DuplicateCourses}`}>
                <DuplicateCourses />
              </Route>
              <Route exact path={`${path}/${AdminPathEnum.QADashboard}`}>
                <MultiQADashboard />
              </Route>
              <Route exact path={`${path}/${AdminPathEnum.SystemReports}`}>
                <SystemReports />
              </Route>
              <Route exact path={`${path}/${AdminPathEnum.CourseActivityCheck}`}>
                <CourseActivityCheck />
              </Route>
              <Route path={`${path}/${AdminPathEnum.ContentLibrary}`}>
                <ContentLibrary />
              </Route>
              <Route exact path={`${path}/${AdminPathEnum.SurveyDistributor}`}>
                <SurveyDistributor />
              </Route>
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </div>
    </main>
  );
}
